function colors(status) {
    let cor_ped = "";
    let cor_analise = "";
    let cor_faturado = "";
    let cor_separacao = "";
    let cor_encaminhado = "";
    let cor_entregue = "";
  
    switch (status) {
      case "CANCELADO":
      case "PEDIDO CANCELADO":
      case "PEDIDO NAO FATURADO":
      case "PEDIDO NOTA CANCELADA":
      case "PEDIDO RETORNADO":
      case "RETORNADO/DEVOLVIDO":
        cor_ped = "danger";
        break;
  
      case "PARCIALMENTE ENTREGUE":
      case "PEDIDO PARCIALMENTE RECUSADO":
      case "PEDIDO PARCIALMENTE RETORNADO":
        cor_ped = "success";
        cor_analise = "success";
        cor_faturado = "success";
        cor_separacao = "success";
        cor_encaminhado = "success";
        cor_entregue = "warning";
        break;
  
      case "PEDIDO ENTREGUE":
        cor_ped = "success";
        cor_analise = "success";
        cor_faturado = "success";
        cor_separacao = "success";
        cor_encaminhado = "success";
        cor_entregue = "success";
        break;
  
      case "PEDIDO EM SEPARACAO":
        cor_ped = "success";
        cor_analise = "success";
        cor_faturado = "success";
        cor_separacao = "warning";
        break;
  
      case "PEDIDO EM TRANSITO":
        cor_ped = "success";
        cor_analise = "success";
        cor_faturado = "success";
        cor_separacao = "success";
        cor_encaminhado = "warning";
        break;
  
      case "FATURAMENTO PENDENTE":
        cor_ped = "success";
        cor_analise = "success";
        cor_faturado = "warning";
        break;
  
      case "PEDIDO FATURADO":
        cor_ped = "success";
        cor_analise = "success";
        cor_faturado = "success";
        break;
  
      case "FINANCEIRO NAO APROVADO":
        cor_ped = "success";
        cor_analise = "danger";
        break;
  
      case "FINANCEIRO APROVADO":
        cor_ped = "success";
        cor_analise = "success";
        cor_faturado = "warning";
        break;
  
      default:
        break;
    }
    
    return {
      cor_ped,
      cor_analise,
      cor_faturado,
      cor_separacao,
      cor_encaminhado,
      cor_entregue,
    }
}
export default colors;