
import useAuthStore from '../../store/authStore.js'

async function redirectToLogin(next, to) {
  next({
    path: "/auth/signin",
    query: {
      redirect: to.fullPath,
    },
  });
}
export async function authMiddleWare(to, from, next) {
  const store = useAuthStore();

  const token = JSON.parse(localStorage.getItem("token"));

  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }

  if (!token) {
    redirectToLogin(next, to);
    return;
  }

  const currentTime = Math.floor(Date.now() / 1000);
  const tokenExpired = token.issued_at + token.expires_in < currentTime;

  if (tokenExpired) {
    try {
      await store.dispatch("refreshToken");
      next();
    } catch (error) {
      redirectToLogin(next, to);
    }
  } else {
    next();
  }
}