import { createStore } from "vuex";
import axios from "@/axios/axios_users";
import { ref } from "vue";

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default () => createStore({
  state: {
    myDebts: ref([]),
    error: ref(''),
    loading: false,
  },

  mutations: {
    SetData(state, data) {
      state.myDebts = ref(data);
    },
  },

  actions: {
    async getData(context) {

      context.state.loading = true;

      try {
        const URL = '/client/my-debits'
        context.state.loading = true;
        const response = await axios.get(URL, {
          headers: { 
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
          }
        });

        context.commit('SetData', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.error = error?.response?.data?.details ? error?.response?.data?.details : 'Erro ao buscar dados';
        context.state.loading = false;
      }
    },
  },
});