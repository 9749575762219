<script setup>
import { defineProps, ref, computed, onMounted } from "vue";
import Chart from "chart.js/auto";

const props = defineProps({
  score: {
    type: Number,
    required: true,
  },
  max: {
    type: Number
  }
});

const chartRef11 = ref(null);

const percentage = computed(() =>
  props.score
    ? props.score.toFixed()
    : 0
);

onMounted(() => {
  new Chart(chartRef11.value, {
    type: "doughnut",
    data: {
      labels: ["Feito", "Restante"],
      datasets: [
        {
          label: "",
          weight: 9,
          cutout: 60,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 0,
          backgroundColor: ["#4BB543 ", "#f53939"],
          data: [percentage.value, props.max - percentage.value],
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  });
});
</script>
<template>
  <div class="h-100">
    <div class="p-3 card-body">
      <div class="row">
        <div class="text-center col-12">
          <div class="mt-4 mb-2 chart">
            <canvas ref="chartRef11" class="chart-canvas" height="200" width="200" style="width: 400px !important; height: 400px !important;"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.chart-canvas{
  width: 200px !important;
  height: 200px !important;
}
</style>
