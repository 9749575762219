<script setup>

const segment = process.env.SEGMENT


import {computed} from "vue";
import { useStore } from "vuex";
import SidenavList from "./SidenavList.vue";
// import logo from '@/assets/img/logos/brasal/brasal_incorporacoes.png';
// import logoWhite from "@/assets/img/logos/brasal/brasal_incorporacoes.png";
// import image from "@/assets/img/logos/brasal/brasal_incorporacoes.png";

const store = useStore();
// const isRTL = computed(() => store.state.isRTL);
const layout = computed(() => store.state.layout);
const sidebarType = computed(() => store.state.sidebarType);
// const darkMode = computed(() => store.state.darkMode);
</script>

<template>
<!--  <div class="position-absolute w-100 min-height-300 top-0 ">-->
<!--    <span class="mask bg-segment-primary-color"></span>-->
<!--  </div>-->

  <div
id="sidenav-main" class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4 py-2 px-3 bg-white shadow-lg"
    :class="` ${sidebarType} ${false ? 'fixed-end me-3 rotate-caret' : 'fixed-start ms-3'} ${layout === 'landing' ? 'bg-transparent shadow-none' : ''}`"
    style="margin-top: 70px !important; z-index: 999 !important">
    <div class="sidenav-header d-flex justify-content-center align-items-center">
      <router-link class="m-0" to="/">
          <img
            :src="`/brasal/logo_conecta_horizontal.svg`"
            :class="segment" alt="Logo Brasal" />
      </router-link>
    </div>

    <div class="py-2">
      <hr class="mt-0 horizontal dark" />
    </div>
    <sidenav-list />
  </div>
</template>

<style>
  .brasal_combustiveis, 
  .brasal_veiculos,
  .brasal_refrigerantes,
  .brasal_energia,
  .brasal_incorporacoes {
    width:100%;
    height:70px;
  }

  .centro_corporativo {
    width:250px;
    height:100px;
  }

  .header-page {
    background-position-y: 50%;
  }
  
</style>
