<template>

  <div class="card border-0 my-2 py-5 py-lg-5 px-4 d-flex flex-column  mx-auto w-95" >
    <img :src="icon" class="w-50 mx-auto mb-2">
    <h4 class="text-center text-muted text-green mb-0 mt-2"> {{ props.title }} </h4>
    <p class="mt-3 text-center w-lg-75 mx-auto">{{props.subtitle}}</p>
    <div class="card-footer bg-transparent text-center p-0 d-flex flex-column justify-content-between">
      <p class="text-center text-muted mb-0">
        <a class="text-center text-muted mb-0 text-sm text-nowrap">
          <router-link :to="{ name: 'SignIn' }" class="segment-primary-color text-bold cursor-pointer  ">Voltar a tela de login
          </router-link>
        </a>
      </p>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  icon: String,
  title: String,
  subtitle: String,
});

const icon = require(`@/assets/img/${props.icon}.png`);
</script>