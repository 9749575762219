<script setup>
import { defineProps, ref, computed, onBeforeUnmount } from "vue";
import useIndicatorsStore from "../../../../store/IndicatorsStore";
import { useRouter } from "vue-router";

const store = useIndicatorsStore();

const router = useRouter();

const props = defineProps({
  indicator: {
    type: Object,
    required: true,
  },
  campaignId: {
    type: Number,
    required: true,
  },
  periodId: {
    type: Number,
    required: true,
  },
  disableSend: {
    type: Boolean,
    required: true,
  },
});

let videoStream = null;

const videoRef = ref(null);
const showDetailsModal = ref(false);
const showPictureModal = ref(false);
const showTutorial = ref(false);
const photo = ref("");
const photoLoading = ref(false);
let isFrontCamera = ref(true);

const image = computed(() =>
  props.indicator?.photograph?.based_image
    ? props.indicator?.photograph?.based_image
    : null
);
const attempt = computed(() => props.indicator?.evaluation?.attempt);
const status = computed(() =>
  props.indicator?.evaluation?.status ? props.indicator?.evaluation?.status : 0
);
const reason = computed(
  () =>
    props.indicator?.photograph?.param_evaluation?.find(
      (element) => element.attempt == attempt.value
    )?.reason
);
const description = computed(() => props.indicator?.description);
const expired = computed(() => {
  const dateParts = props.indicator?.final_photo_period?.split("-");
  if (!dateParts) return false;
  const finalPhotoPeriod = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  finalPhotoPeriod.setHours(23, 59, 59, 999);


  const currentDate = new Date();
  const nextDayMidnight = new Date(currentDate);
  nextDayMidnight.setDate(currentDate.getDate() + 1);
  nextDayMidnight.setHours(0, 0, 0, 0);

  return new Date(finalPhotoPeriod) < nextDayMidnight;
});

function formatDate(dataString) {
  var partes = dataString.split("-");
  var novaData = partes[2] + "/" + partes[1] + "/" + partes[0];
  return novaData;
}

async function sendPhoto() {
  if (photoLoading.value) {
    return;
  }

  let flexibleCampaignId = props.campaignId;
  let flexiblePeriodId = props.periodId;
  let flexibleParamIndicatorId = props.indicator.id;
  let flexibleClientCode = JSON.parse(localStorage.getItem("user")).current_client.clientCode;
  let storage = photo.value;

  photoLoading.value = true;

  try {
    const imagePromise = store.dispatch("sendImage", {
      flexibleCampaignId,
      flexiblePeriodId,
      flexibleParamIndicatorId,
      flexibleClientCode,
      storage,
    });

    await Promise.all([
      imagePromise.then(() => {
        photoLoading.value = false;
        setTimeout(router.go(0), 1500);
      }),
    ]);
  } catch (error) {
    photoLoading.value = false;
    console.error("Erro:", error);
  }
}

function closePhotoModal() {
  photo.value = "";
  showPictureModal.value = false;
}

const takePhoto = () => {
  const video = videoRef.value;
  if (!video || !video.srcObject) {
    console.error("Houve um problema ao inicializar a sua camera");
    return;
  }

  const canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

  const imageData = canvas.toDataURL("image/png");

  photo.value = imageData;

  stopCamera();
};

const startCamera = async (facingMode = "user") => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode },
    });
    videoStream = stream;
    const video = videoRef.value;

    if (video) {
      video.srcObject = stream;
    }
  } catch (error) {
    console.error("Erro ao acessar a câmera: ", error);
  }
};

const turnCamera = () => {
  isFrontCamera.value = !isFrontCamera.value;
  stopCamera();
  startCamera(isFrontCamera.value ? "user" : "environment");
};

const stopCamera = () => {
  if (videoStream) {
    videoStream.getTracks().forEach((track) => track.stop());
    videoStream = null;
  }
};

const startVideo = () => {
  //videoRef.value = document.getElementById('video');

  showPictureModal.value = true;
  startCamera();
};

onBeforeUnmount(() => {
  stopCamera();
});

const extractVideoId = (url) => {
  // Expressão regular para encontrar o ID do vídeo
  var regex = /[?&]v=([^&#]+)/;
  var matches = url.match(regex);

  if (matches) {
    return matches[1];
  } else {
    regex = /youtu\.be\/([^?&#]+)/;
    matches = url.match(regex);
    if (matches) {
      return matches[1];
    } else {
      return null;
    }
  }
};

</script>
<template>
  <div class="card mx-auto">
    <div v-if="new Date() > new Date(new Date(props?.indicator?.final_photo_period).getTime() + 24 * 60 * 60 * 1000) && !status" class="text-center py-2 bg-gray">Período de envio encerrado</div>
    <div v-else-if="!status" class="text-center py-2 bg-gray">Foto não enviada</div>
    <div v-else-if="status == 1" class="text-center py-2 bg-success text-white">
      Foto Aprovada
    </div>
    <div v-else-if="status == 2" class="text-center py-2 bg-info text-white">
      Aguardando Avaliação
    </div>
    <div v-else-if="status == 3" class="text-center py-2 bg-danger text-white">
      Foto Reprovada ({{ attempt }}/3)
    </div>
    <img
      v-if="image"
      class="campaign-image mx-auto"
      :src="'data:image/png;base64,' + image"
      :alt="indicator.description"
    />
    <img
      v-else
      class="campaign-image mx-auto"
      src="/img/placeholder-image.png"
    />
    <div class="w-100 pt-2 text-center">
      <h5>{{ props.indicator.name }}</h5>
    </div>

    <div class="campaign-actions d-flex align-items-center">
      <button
        class="mb-0 btn btn-outline-primary export w-100 m-2 mb-2"
        @click="showDetailsModal = true"
      >
        Detalhes
      </button>
    </div>
  </div>

  <div class="modal" :class="{ 'is-active': showDetailsModal }">
    <div class="modal-background" @click="showDetailsModal = false"></div>
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="exampleModalLabel" class="modal-title">Detalhe da foto</h5>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body text-center">
            <h4>{{ indicator.name }}</h4>
            <small
              >Período de Apuração:<br />{{
                `${formatDate(indicator.initial_photo_period)} até ${formatDate(indicator.final_photo_period)}`
              }}</small
            >
          </div>

          <div v-if="indicator?.movieLink" class="tutorial-button">
            <button
              id="toogle-button"
              type="button"
              class="btn btn-secondary btn-sm m-2"
              @click="showTutorial = !showTutorial"
            >
              {{ showTutorial ? "Ver Foto" : "Ver Tutorial" }}
            </button>
          </div>

          <div v-if="showTutorial" class="m-auto mb-5 midia-box">
            <iframe
              :src="`https://www.youtube.com/embed/${extractVideoId(indicator?.movieLink)}?rel=0`"
              allowfullscreen
            ></iframe>
          </div>
          <div v-else class="card m-auto midia-box mb-5 w-100">
            <a
              v-if="image != '/img/placeholder-image.png'"
              :href="'data:image/png;base64,' + image"
              download
            >
            <div v-if="new Date() > new Date(new Date(props?.indicator?.final_photo_period).getTime() + 24 * 60 * 60 * 1000) && !status" class="text-center bg-gray p-1">
                <div>Período de envio encerrado</div>
              </div>
              <div v-else-if="!status" class="text-center bg-gray p-1">
                <div>Aguardando Envio</div>
              </div>
              <div
                v-else-if="status == 1"
                class="text-center bg-success text-white p-1"
              >
                <div>Foto Aprovada!</div>
              </div>
              <div v-else-if="status == 2" class="text-center bg-info p-1">
                <div>Aguardando Avaliação</div>
              </div>
              <div
                v-else-if="status == 3"
                class="text-center bg-danger text-white p-1"
              >
                <div>Foto Reprovada ({{ attempt }}/3)</div>
              </div>
              <div v-if="image" class="img-div">
                <img
                  class="campaign-image mx-auto"
                  :src="'data:image/png;base64,' + image"
                  :alt="indicator.description"
                />
              </div>
            </a>
            <div v-else class="img-div">
              <img
                class="campaign-image mx-auto"
                :src="'data:image/png;base64,' + image"
                :alt="indicator.description"
              />
            </div>
          </div>
        </div>

        <div v-if="status == 3 && reason" class="mb-1 ml-2 mt-4">
          <label class="form-label" for="exampleFormControlTextarea1"
            >Motivo da reprovação:</label
          >
          <textarea
            id="exampleFormControlTextarea1"
            v-model="reason"
            readonly
            class="form-control"
            rows="3"
          ></textarea>
        </div>

        <div v-if="description" class="mb-1 ml-2 mt-4" style="text-align: left">
          <label class="form-label" for="exampleFormControlTextarea1"
            >Descrição:</label
          >
          <textarea
            id="exampleFormControlTextarea1"
            v-model="description"
            readonly
            class="form-control"
            rows="3"
          ></textarea>
        </div>

        <table class="table">
          <tfoot>
            <tr>
              <th>Pontos</th>
              <th style="text-align: center">
                {{
                  `${indicator.evaluation.clientPoints}/${indicator.evaluation.totalPoints}`
                }}
              </th>
            </tr>
            <tr>
              <th>Tentativas</th>
              <th style="text-align: center">{{ attempt }}/3</th>
            </tr>
          </tfoot>
        </table>

        <span
          id="file-errorecho $missao_atual['id']"
          style="display: none"
          class="text-danger ml-auto mr-auto mb-3"
        ></span>
      </div>
      <div
        v-if="!disableSend"
        class="modal-footer d-flex flex-row align-items-center justify-content-between"
      >
        <div
          v-if="!expired && attempt < 3 && (status == 0 || status == 3)"
          class="mr-auto"
        >
          <div
            id="btn-carregar-fotoecho $missao_atual['id']"
            class="btn btn-success mr-2"
            @click="startVideo"
          >
            Enviar Foto
          </div>
        </div>
        <button
          id="close-button"
          type="button"
          class="btn btn-primary"
          @click="showDetailsModal = false"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>

  <div
    v-if="!disableSend"
    class="modal"
    :class="{ 'is-active': showPictureModal }"
  >
    <div class="modal-background" @click="showPictureModal = false"></div>
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="exampleModalLabel" class="modal-title">Tirar foto</h5>
      </div>
      <div class="modal-body">
        <div v-if="photoLoading">
          <Loading loading-text="Enviando sua foto" short />
        </div>
        <div v-else-if="photo == ''" class="m-auto midia-box mb-5">
          <video id="video" ref="videoRef" autoplay></video>
        </div>
        <div v-else class="m-auto midia-box mb-5 w-100">
          <img class="campaign-image mx-auto" :src="photo" />
        </div>

        <div
          class="modal-footer p-0 mt-1 d-flex flex-row align-items-center justify-content-between"
        >
          <div>
            <button
              v-if="photo == ''"
              type="button"
              class="btn btn-success"
              :disabled="photoLoading"
              @click="takePhoto"
            >
              <i class="fa fa-camera ms-1" aria-hidden="true"></i
              ><span class="ms-1">Tirar</span>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-success"
              :disabled="photoLoading"
              @click="sendPhoto"
            >
              Enviar<i class="fa fa-upload ms-1" aria-hidden="true"></i>
            </button>
            <button
              v-if="photo == ''"
              type="button"
              class="btn btn-info ms-2"
              :disabled="photoLoading"
              @click="turnCamera"
            >
              <i class="fa fa-sync ms-1" aria-hidden="true"></i
              ><span class="ms-1">Virar Câmera</span>
            </button>
          </div>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="photoLoading"
            @click="closePhotoModal"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.disabled-link {
  pointer-events: none;
  text-decoration: none;
  cursor: not-allowed;
}
.card {
  position: relative;
  margin: 0.25rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.campaign-image {
  width: 100%;
  object-fit: contain;
  max-width: 500px;
}

.info {
  margin-top: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.card-title {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-header {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
}

.title {
  font-size: 1rem;
  font-weight: 700;
}

.favorite-icon {
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}

.bg-gray {
  background-color: #cccccc;
}

.modal {
  display: none;
}

.modal.is-active {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 50%;
  max-height: 80%;
  overflow: auto;
}

@media screen and (max-width: 630px) {
  .modal-content {
    max-width: 100% !important;
    width: 80% !important;
  }

  .midia-box iframe {
    min-height: 50px;
  }

  .midia-box {
    width: 95% !important;
  }
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.label-colored {
  width: 80%;
  margin: auto;
  text-align: center;
  border-radius: 10px;
}

.midia-box {
  width: 70%;
  margin: auto;
  margin-bottom: 50px;
}

.midia-box iframe {
  width: 100%;
  margin: auto;
  min-height: 280px;
}

.midia-box video {
  width: 100%;
  margin: auto;
  min-height: 280px;
}

@media screen and (max-width: 630px) {
  .tutorial-button {
    width: 100% !important;
    text-align: center;
  }
  .tutorial-button button {
    width: 80%;
  }
}

.img-div {
  text-align: center;
}
</style>
