<script setup>
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import ItemCart from "@/views/conecta-brasal-cliente/pedidos/carrinho/components/ItemCart.vue";
import {useStore} from "vuex";
import useProductStore from "../../../../store/ProductsStore";
import useCompaniesStore from '@/store/companiesStore'
import router from "@/router";
import {debounce} from "lodash";
import Empresa from "@/views/conecta-brasal-cliente/empresas/empresa/Main.vue"
// import Loading from "@/components/Loading.vue";

const clientData = JSON.parse(localStorage.getItem("clientData"));
const clientHasNet = ref(false);
const store = useStore();
const productsStore = useProductStore();
const companiesStore = useCompaniesStore;
const modalContent = ref('order');
const products = ref([]);
let displayCount = ref(10);
const promotions = ref([]);
const companies = ref([]);
const currentUserDoc = ref('');

onBeforeMount(() => {
  const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null

  if (!user || !user.storeAccess) {
    router.push('/campanhas')
  }
  
  store.commit("hideFooter");
  store.commit("hideFooter");
  promotions.value = JSON.parse(localStorage.getItem("promotions")) || [];
  products.value = JSON.parse(localStorage.getItem("productsOnCart")) || [];
  clientHasNet.value = JSON.parse(localStorage.getItem("user")).clients.length > 1;
  currentUserDoc.value = JSON.parse(localStorage.getItem("user")).cnpj_cpf;
  if(clientHasNet.value) {
    const getCompaniesPromise = companiesStore.dispatch("fetchCompanies");
    Promise.all([
      getCompaniesPromise
          .then(() => {
            companies.value = companiesStore.getters.companies.filter(
                (company) => company.cnpj_cpf !== currentUserDoc.value
            );
          })
    ]);

  }
  if (products.value.length === 0) {
    router.push("/produtos");
  }
  handleQuantityChanged();
});

onBeforeUnmount(() => {
  store.commit("hideFooter");
});

const shopErrors = ref({
  minimumOrder: false,
  availableFunds: false,
  vasilhame: false,
});

const shopAcceptance = ref({
  availableFunds: false,
  vasilhame: false,
});
const vasilhames = ref([]);
function handleQuantityChanged() {
  products.value = JSON.parse(localStorage.getItem("productsOnCart")) || [];
  if (products.value.length === 0) {
    return;
  }
  if (itemCount.value === 0) {
    return false;
  }

  if (total.value < clientData.Minimoorder) {
    shopErrors.value.minimumOrder = true;
  } else {
    shopErrors.value.minimumOrder = false;
  }

  if (total.value > clientData.Limitecredito) {
    shopErrors.value.availableFunds = true;
  } else {
    shopErrors.value.availableFunds = false;
  }

  let vasilhameValidate = false;
  vasilhames.value = [];
  products.value.forEach((item) => {
    if (((item?.vasilhame !== "" && item?.vasilhame !== undefined)) && item.total > 0) {
      
      vasilhameValidate = true;
      vasilhames.value.push({
        name: item?.descricao || item?.description,
        qtd: item?.vasilhame || item?.empities_per_combo,
        total: item.total,
        complement: item.complemento,
      });
    } else if (item?.empties && item?.empties > 0 && item.total > 0) {
      vasilhameValidate = true;
      const items = item.empties_per_combo;
      items.forEach((product) => {
        vasilhames.value.push({
          name: product.description,
          qtd: product.empties_per_combo,
          total: item.total,
        });
      });
    }
  });
  if (vasilhameValidate && vasilhames.value.length > 0) {
    shopErrors.value.vasilhame = true;
  } else {
    shopErrors.value.vasilhame = false;
  }
}

function handleItemRemoved() {
  products.value = JSON.parse(localStorage.getItem("productsOnCart")) || [];
  handleQuantityChanged();
}

const orderStatus = ref(0);
const showOrderModal = ref(false);
const loadingOrder = ref(false);
const responseMessage = ref("");
const outOfStockItems = ref([]);
const successResponse = ref({});
async function sendOrder() {
  orderStatus.value = 0;
  showOrderModal.value = true;

  loadingOrder.value = true;

  try {
    const sendOrderPromise = productsStore.dispatch("sendOrder", {
      flexibleClient: JSON.parse(localStorage.getItem("user")).current_client.clientCode,
      products: JSON.parse(localStorage.getItem("productsOnCart") || "[]"),
    });

    await Promise.all([
      sendOrderPromise
          .then(() => {
            orderStatus.value = 1;

            successResponse.value = productsStore?.state?.orderResponse;
          })
          .catch((error) => {
            orderStatus.value = 2;
            if (error?.response?.data?.details?.length) {
              responseMessage.value = error?.response?.data?.error;

              outOfStockItems.value = error?.response?.data?.details;
            } else {
              responseMessage.value = error?.response?.data?.message;
            }
          })
    ]);
  } catch (error) {
    console.error("Erro:", error);
  }
  loadingOrder.value = false;
}
function converterData(dataAAAAMMDD) {
  var ano = dataAAAAMMDD?.substring(0, 4);
  var mes = dataAAAAMMDD?.substring(4, 6);
  var dia = dataAAAAMMDD?.substring(6, 8);
  return dia + "/" + mes + "/" + ano;
}

const subtotal = ref(0);
const discount = ref(0);

let debouncedDiscount = debounce(() => {
  if (products.value.length === 0) {
    return;
  }
  let totalDiscount = 0;
  products.value.forEach((item) => {
    if (item && item.preco &&item.preco > item.usePrice) {
      const priceDifference =item.preco - item.usePrice;
      item.discount = priceDifference * item.total;
      totalDiscount += item.discount;
    } else if (item) {
      item.discount = 0;
    }
  });
  discount.value = totalDiscount;
}, 300);

let debouncedSubtotal = debounce(() => {
  if (products.value.length === 0) {
    return;
  }
  const calculatedSubtotal = products.value.reduce((acc, item) => {
    if (item && (item?.preco || item?.price)) {
      return acc + (item?.preco || item?.price) * item.total;
    } else {
      return acc;
    }
  }, 0);
  subtotal.value = calculatedSubtotal;
}, 300);

watch(products, () => {
  debouncedDiscount();
  debouncedSubtotal();
});

const total = computed(() => {
  const subtotalValue = products.value.reduce((acc, item) => {
    if (item && item.usePrice) {
      return acc + item.usePrice * item.total;
    }
    return acc;
  }, 0);
  return subtotalValue;
});

const itemCount = computed(() => {
  const itemCountValue = products.value.reduce((acc, item) => {
    if (item && item.total) {
      return acc + item.total;
    }
    return acc;
  }, 0);
  return itemCountValue;
});

watch(() => itemCount.value, (newValue) => {
  if (newValue === 0) {
    router.push("/produtos");
  }
});

function formatCurrency(value) {
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function formatInteger (value) {
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
</script>

<template>

  <div class="bg-white mb-4 mx-3 rounded" style="padding: .2rem 1.6rem;">
    <div class="d-flex justify-content-between align-items-center gap-auto py-1">
      <button class="btn btn-link m-0 p-0" @click="router.go(-1)">
        <i class="fas fa-chevron-left me-1"></i> Voltar
      </button>
      <label class="mb-0 font-weight-bold" style="margin-left: -1.3rem; font-size: 1.2rem">
        <h2 class="inline">Carrinho</h2>
      </label>
      <div></div>
    </div>
  </div>

  <main class="bg-white rounded py-4 px-2 mx-3">
    <div v-if="Object.values(shopErrors).includes(true)">
      <Message v-if="shopErrors.minimumOrder" severity="error" class="msg"
               >
        <h6 class="text-sm font-weight-bold m-0">
          <b>É necessário atingir o pedido mínimo de R$ <span id="minimumBox">{{
              clientData?.Minimoorder
            }}</span> reais.</b>
        </h6>
      </Message>
      <Message v-if="shopErrors.availableFunds" severity="error" class="msg">
        <h6 class=" text-sm font-weight-bold">
          <b>Limite de crédito ultrapassado: R$ {{ (clientData?.Limitecredito) }} </b>
        </h6>
        <p class=" text-sm">
          Confirme o valor acima do limite de crédito ou ajuste a quantidade de itens.
        </p>
        <span class="d-flex flex-row justify-content-start align-items-center">
    <Checkbox v-model="shopAcceptance.availableFunds" inputId="limit-check" binary variant="filled"/>
    <label class="m-0 ms-1" style="font-size: .9rem;" for="limit-check">Confirmar compra acima do limite</label>
  </span>
      </Message>
      <Message v-if="shopErrors.vasilhame && vasilhames.length > 0" severity="warn" class="msg mx-auto">
        <h6 class=" text-sm font-weight-bold">
          <b>Atenção! Produtos com vasilhame adicionados:</b>
        </h6>
        <p class=" text-sm mb-0">
          Confira a quantidade de vasilhame adicionada para os produtos abaixo:
        </p>
        <div class="d-flex flex-column text-sm m-0 font-weight-normal ">
          <div v-for="(item, index) in vasilhames" :key="index" class="d-flex flex-row justify-content-between my-1">
            <span class="">{{
                item.name + " " + (item?.complement?.replace(/ - R\$$/, '').replace(/\(CX\/\d+\)$/, '') || "") + ":"
              }}</span>
            <span>{{ formatInteger(item.qtd * item.total) }}</span>
          </div>
          <span class="d-flex flex-row justify-content-start align-items-center mt-2">
      <Checkbox v-model="shopAcceptance.vasilhame" inputId="vasilhame-check" binary variant="filled"/>
      <label class=" m-0 ms-1" style="font-size: .9rem;" for="vasilhame-check">Confirmar posse dos vasilhames</label>
    </span>
        </div>
      </Message>

    </div>

    <div class="itens-container">

    <ItemCart
        v-for="item in products.slice(0, displayCount)"
        :key="item.ean"
        :item="item"
        @quantity-changed="handleQuantityChanged"
        @item-removed="handleItemRemoved"
    />
    </div>
    <div class="grid-container-footer w-100 mt-auto rounded">
      <div class="bg-white container-glossary">
        <p class="glossary">Resumo: <span class="item-count" style="font-size: .8rem;">{{ itemCount }} {{ itemCount > 1 ? 'itens' : 'item' }}</span></p>
        <div class="detail d-flex flex-row justify-content-between">
          <span>Subtotal:</span>
          <span class="font-weight-bold">R$ {{ formatCurrency(subtotal) }}</span>
        </div>
        <div v-if="discount" class=" detail d-flex flex-row justify-content-between" style="color: #059669;">
          <span>Descontos:</span>
          <span class="font-weight-bold">R$ {{ formatCurrency(discount) }}</span>
        </div>
      </div>
      <p class="total mb-0">Total:</p>
      <p class="value mb-0">R$ {{ formatCurrency(total) }}</p>
      <button class="grid-item-button btn btn-danger"
              :disabled="loadingOrder|| shopErrors.minimumOrder ||
                   (shopErrors.availableFunds && !shopAcceptance.availableFunds) ||
                   (shopErrors.vasilhame && !shopAcceptance.vasilhame)"
              @click="sendOrder"
      >
        Finalizar Pedido
      </button>
    </div>

  </main>
  <div class="modal" :class="{ 'is-active': showOrderModal }">
    <div class="modal-background" @click="() => orderStatus != 0 ? showOrderModal = false : ''"></div>
    <div class="modal-content">
      <div v-if="orderStatus == 0" class="modal-header">
        <h5 id="exampleModalLabel" class="modal-title">
          Enviando pedido
        </h5>
      </div>
      <div v-else-if="orderStatus == 1" class="modal-header bg-success">
        <h5 id="exampleModalLabel" class="modal-title text-white">
          Pedido enviado!
        </h5>
      </div>
      <div v-else-if="orderStatus == 2" class="modal-header bg-warning">
        <h5 id="exampleModalLabel" class="modal-title text-white">
          Pedido não enviado!
        </h5>
      </div>

      <div v-else-if="orderStatus == 4" class="modal-header bg-primary">
        <h5 id="exampleModalLabel" class="modal-title text-white">
          Selecione uma empresa
        </h5>
        </div>

      <div v-if="orderStatus == 0" class="modal-body text-center my-4 ">
        Seu pedido está sendo processado. Por favor aguarde...
      </div>
      <div v-else-if="orderStatus == 1" class="modal-body text-center my-2">
        <div v-if="modalContent = 'order'">
          <div>
            <b>Número do pedido:</b>
            <p>
              {{
                successResponse?.ORDEM
              }}
            </p>
            <b>Data provável de entrega:</b>
            <p>
              {{
                converterData(successResponse?.ENTREGA)
              }}
            </p>
            <b>Valor:</b>
            <p>
              {{
                `R$ ${successResponse?.VALOR?.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
              }}
            </p>

          </div>

          <div v-if="clientHasNet" class="mt-auto" style="text-align: center !important;">
            <hr/>
            <b class="mb-1">Que tal aproveitar e fazer um pedido em outra empresa também?</b>
            <button type="button"
                    class="btn btn-primary w-100 mt-2 flex flex-row align-items-center justify-content-center"
                    @click="orderStatus = 4">
              Ver minhas empresas
              <i class='ni ni-shop ms-2'></i>
            </button>
          </div>
        </div>


      </div>

      <div v-else-if="orderStatus == 2" class="modal-body text-center my-4">
        <h4>{{ responseMessage }}</h4>
        <div v-if="outOfStockItems?.length && outOfStockItems.length > 0">
          <hr/>
          <div
              v-for="product in outOfStockItems"
              :key="product.sku"
          >
            <b>{{ product.message }}</b>
            <p>
              {{
                `${product.availableQty} em estoque`
              }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="orderStatus == 4">
        <Empresa class="mt-2 " v-for="company in companies" :key="company.id" :company="company" :companyLogged="false"/>
      </div>
      <Loading v-if="loadingOrder" short loading-text=""/>
      <div class="modal-footer pb-0">
        <button
            v-if="orderStatus == 4"
            id="close-button"
            type="button"
            class="btn btn-primary me-auto"
            @click="() => orderStatus = 3"
        >
          Voltar
        </button>
        <button
            id="close-button"
            type="button"
            class="btn btn-primary"
            :disabled="orderStatus == 0"
            @click="() => router.go(0)"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  display: flex;
  flex-direction: column;
  max-height: none;
  min-height: 100vh;
  max-width: 85vw;
  box-sizing: border-box;
  background-color: #fff !important;
}

.modal-content {
  max-width: 90vw;
  padding: 0;
}

main {
  max-width: 95vw;
}

.container-glossary {
  grid-row-start: 1;
  grid-column: span 2;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;

}

.grid-container-footer {
  display: grid;
  gap: .2rem;
  padding: 0 .5rem;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  margin-top: auto;
}

@media (min-width: 1028px) {

  body {
    display: flex;
    flex-direction: row;
    padding-left: 2rem;
  }
  main {
    padding-bottom: 2vh;
    display: grid;
    gap:2rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-container-footer {
    bottom: auto;
    grid-column: 2;

  }

  .itens-container {
    grid-row-start:2;
    grid-column-start:1;
  }

}

.msg {
  border-radius: 1.2rem;
}

.p-message {
  margin: 0 auto .7rem !important;
}

.p-message .p-message-content {
}

::v-deep .p-message-close {
  display: none;
}

::v-deep .p-message-wrapper {
  gap: 1rem;
}
::v-deep .p-message-error {
  background-color: #fcd6d2 !important; /* Cor de fundo mais forte */
  border-color: #f8d1d0 !important; /* Cor da borda mais clara */
  color: #8b3f3f !important; /* Cor do texto mais clara */
}

.grid-item-button {
  grid-column: 2;
  grid-row: span 2;
  width: 100%;
  height: 90%;
  font-size: 1rem;
  margin: auto;
  margin-right: 0;
}

.total {
  grid-column: 1;
  font-size: 0.9rem;
  font-weight: bold;
}

.value {
  font-size: 1.2em;
  grid-row-start: 2;
  grid-row: 3;
  font-weight: bold;
}

.detail {
  font-size: 0.9em;
}

.item-count {
  font-size: 0.8em;
  align-self: center;
  font-weight: normal !important;
}

.promotions {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  max-width: 100%;
  overflow-x: scroll;
  margin-top: 1rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.glossary {
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.footer {
  display: none !important;
}

::v-deep .p-message-icon {
  display: none;
}


</style>
