import {createStore} from "vuex";
import axios from "@/axios/axios_products";
import {ref} from "vue";

import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default () => createStore({
    state: {
        products: ref([]),
        promotions: ref([]),
        combos: ref([]),
        bonuses: ref([]),
        recents: ref([]),
        productsPagination: ref({}),
        promotionsPagination: ref({}),
        combosPagination: ref({}),
        recentsPagination: ref({}),
        segmentations: ref([]),
        brands: ref([]),
        loading: false,
        productsOnCart: ref(localStorage.getItem('productsOnCart') ? JSON.parse(localStorage.getItem('productsOnCart')) : []),
        selectedSegmentation: "",
        selectedBrand: "",
        clientData: ref({}),

        orderResponse: ref({}),
    },

    mutations: {
        setProducts(state, data) {
            state.products = ref(data.data.products);
            state.brands = ref(data.data.brands);

            state.productsPagination = ref(formatPagination(data.pagination));
        },
        setPromotions(state, data) {
            state.promotions = ref(data.data.products);
            state.promotionsPagination = ref(formatPagination(data.pagination));
        },
        setCombos(state, data) {
            state.combos = data?.data?.products ? ref(data?.data?.products) : data;
            if (data?.data?.products) {
                state.combosPagination = !data?.data?.products && ref(formatPagination(data.pagination));

            }
        },
        setRecents(state, data) {
            state.recents = data?.data ? ref(data.data.products) : data;
            if (data?.data) {
                state.recentsPagination = ref(formatPagination(data.pagination));
            }
        },
        setSegmentations(state, data) {
            state.segmentations = ref(data);
        },
        setProductsOnCart(state, data) {
            state.productsOnCart = ref(data);
            localStorage.setItem('productsOnCart', JSON.stringify(data))
        },
        setClientData(state, data) {
            state.clientData = ref(data);
            localStorage.setItem('clientData', JSON.stringify(data))
        },
        setOrderResponse(state, data) {
            state.orderResponse = ref(data);
        },
        setBonuses(state, data) {
            state.bonuses = ref(data);
        }
    },

    actions: {
        async getLastPurchaseDate(context) {
            context.state.loading = true;
            const URL = '/lastOrder'

            context.state.loading = true;
            const response = await axios.get(URL, {
                headers: {
                    'client-code': JSON.parse(localStorage.getItem('user'))?.current_client.clientCode,
                }
            });
            context.state.loading = false;
            return response.data;
        },
        async getProducts(context, params) {

            context.state.loading = true;

            const {flexibleClient, category, brand, perPage, currentPage} = params

            try {
                const URL = '/catalog/products'
                context.state.loading = true;
                const response = await axios.get(URL, {
                    headers: {
                        'client-code': flexibleClient,
                        category: category.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                        brand,
                        "per-page": perPage,
                        "current-page": currentPage,
                    }
                });
                context.commit('setProducts', response.data);

                context.state.loading = false;

            } catch (error) {
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                }
                context.state.loading = false;
            }
        },
        async getPromotions(context, params) {

            context.state.loading = true;

            const {flexibleClient, perPage, currentPage} = params

            try {
                const URL = '/catalog/news'
                context.state.loading = true;
                const response = await axios.get(URL, {
                    headers: {
                        'client-code': flexibleClient,
                        'per-page': perPage,
                        'current-page': currentPage,
                    }
                });
                context.commit('setPromotions', response.data);
                localStorage.setItem("promotions", JSON.stringify(context.state.promotions));

                context.state.loading = false;

            } catch (error) {
                console.log(error);
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                }
                context.state.loading = false;
            }
        },
        async getCombos(context, params) {

            context.state.loading = true;

            const {flexibleClient, perPage, currentPage} = params

            try {
                const URL = '/catalog/combos'
                context.state.loading = true;
                const response = await axios.get(URL, {
                    headers: {
                        'client-code': flexibleClient,
                        'per-page': perPage,
                        'current-page': currentPage,
                    }
                });
                context.commit('setCombos', response.data);

                context.state.loading = false;

            } catch (error) {
                console.log(error);
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                }
                context.state.loading = false;
            }
        },
        async getRecents(context, params) {

            context.state.loading = true;

            const {flexibleClient, perPage, currentPage} = params

            try {
                const URL = '/catalog/favorites'
                context.state.loading = true;
                const response = await axios.get(URL, {
                    headers: {
                        'client-code': flexibleClient,
                        days: 30,
                        'per-page': perPage,
                        'current-page': currentPage,
                    }
                });
                context.commit('setRecents', response.data);

                context.state.loading = false;

            } catch (error) {
                console.log(error);
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                }
                context.state.loading = false;
            }
        },
        async getSegmentations(context, params) {

            context.state.loading = true;

            const {flexibleClient} = params;

            try {
                const URL = '/images/segments'
                context.state.loading = true;
                const response = await axios.get(URL, {
                    headers: {
                        'client-code': flexibleClient,
                    }
                });

                context.commit('setSegmentations', response.data);

                context.state.loading = false;
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                }
                context.state.loading = false;
            }
        },
        async addToCart(context, params) {

            const {sku, total} = params;

            const existingProductIndex = context.state.productsOnCart.findIndex(item => item.sku === sku);

            if (existingProductIndex !== -1) {
                let product = context.state.productsOnCart[existingProductIndex];

                if (product.estoque < total) {
                    toast.error('Quantidade indisponível no estoque!');
                    return;
                } else if (total <= 0) {
                    context.state.productsOnCart.splice(existingProductIndex, 1)
                } else {
                    context.state.productsOnCart[existingProductIndex].total = total;
                    context.state.productsOnCart[existingProductIndex].usePrice = checkPriceRange(context.state.productsOnCart[existingProductIndex]);
                }

            } else {

                let product = context.state.products.find((element) => element.sku === sku);
                if (!product) {
                    product = context.state.promotions.find((element) => element.sku === sku);
                }
                if (!product) {
                    product = context.state.bonuses.find((element) => element.sku === sku);
                }
                if (!product) {
                    product = context.state.combos.find((element) => element.sku === sku);
                }
                if (!product) {
                    product = context.state.recents.find((element) => element.sku === sku);
                }

                if (product) {
                    if (product.estoque < total) {
                        toast.error('Quantidade indisponível no estoque!');
                        return;
                    } else if (total <= 0) {
                        return;
                    } else {
                        product.total = total;
                        product.usePrice = checkPriceRange(product)
                        context.state.productsOnCart.push(product)
                    }
                }
            }

            context.commit('setProductsOnCart', context.state.productsOnCart);
        },
        async getBonus(context, params) {

            const {flexibleClient, total} = params;

            try {
                const URL = '/catalog/bonus'
                const response = await axios.get(URL, {
                    headers: {
                        'client-code': flexibleClient,
                        total,
                    }
                });


                context.commit('setBonuses', response.data.data?.products);
                const bonusesSkus = response.data.data?.products.map(bonus => bonus.sku);
                const actualCombos = context.state.combos;
                const combos = actualCombos.filter(combo => !bonusesSkus.includes(combo.sku));

                context.commit('setCombos', combos);
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                }
                throw error;
            }
        },
        async clearCart(context) {
            context.state.productsOnCart = [];
        },
        async getClientData(context, params) {

            context.state.loading = true;

            const {flexibleClient} = params;

            try {
                const URL = '/client/limits'
                context.state.loading = true;
                const response = await axios.get(URL, {
                    headers: {
                        'client-code': flexibleClient,
                    }
                });

                context.commit('setClientData', response.data);

                context.state.loading = false;
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.details) {
                    toast.error(error?.response?.data?.details);
                }
                context.state.loading = false;
                throw error;
            }
        },
        async sendOrder(context, params) {

            context.state.loading = true;

            const {flexibleClient, products} = params;

            const sendProducts = products.map((item) => {
                return {
                    faixaVolume2: parseInt(item.faixaVolume2) || parseInt(item?.price_ranges?.faixaVolume2),
                    faixaVolume3: parseInt(item.faixaVolume3) || parseInt(item?.price_ranges?.faixaVolume3),
                    faixaVolume4: parseInt(item.faixaVolume4) || parseInt(item?.price_ranges?.faixaVolume4),
                    faixaVolume5: parseInt(item.faixaVolume5) || parseInt(item?.price_ranges?.faixaVolume5),
                    kdkg2: parseFloat(item.kdkg2) || parseFloat(item?.price_ranges?.kdkg2),
                    kdkg3: parseFloat(item.kdkg3) || parseFloat(item?.price_ranges?.kdkg3),
                    kdkg4: parseFloat(item.kdkg4) || parseFloat(item?.price_ranges?.kdkg4),
                    kdkg5: parseFloat(item.kdkg5) || parseFloat(item?.price_ranges?.kdkg5),
                    price: item.price,
                    sku: item.sku,
                    total: item.total,
                    stock: item.stock,
                    usePrice: item.usePrice,
                    vessel: item?.vessel || item?.empties,
                    complement: item.complemento,
                    product: item.complemento,
                    DATBI: item.DATBI,
                    KVGR1: item?.KVGR1 || item?.table,
                    descricao: item.descricao,
                    bonus: item?.bonus,
                    bonus_proportion: item?.bonus_proportion,
                    budget: item?.budget,
                    products: item?.products
                }
            })

            try {
                const URL = '/sales/'
                context.state.loading = true;
                const response = await axios.post(URL, sendProducts,
                    {
                        headers: {
                            'client-code': flexibleClient,
                        }
                    });

                context.commit('setOrderResponse', response.data.details);
                context.commit('setProductsOnCart', []);

                toast.success("Pedido realizado com sucesso!");

                context.state.loading = false;
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.error) {
                    toast.error(error?.response?.data?.error);
                }

                if (error?.response?.data?.category_budget) {
                    const productsOnCart = JSON.parse(localStorage.getItem('productsOnCart'));

                    // Filtra o array removendo os produtos que estão nas categorias do erro
                    const filteredProducts = productsOnCart.filter(product => {
                        if (product.budget) {
                            // Obtém as chaves de categories no objeto budget
                            const categoryKeys = Object.keys(product.budget.categories);

                            // Se a categoria do erro estiver no array de categories, remove o produto
                            return !categoryKeys.includes(error.response.data.category_budget);
                        }
                        return true; // Manter os produtos que não têm orçamento (budget)
                    });

                    // Atualiza o estado do Vuex ou localStorage, conforme necessário
                    context.commit('setProductsOnCart', filteredProducts);
                }


                context.state.loading = false;
                throw error;
            }
        },
    },
});

function checkPriceRange(product) {
    let usePrice = product?.price || product?.preco;

    const faixaVolume5 = parseInt(product?.faixaVolume5 || product?.price_ranges?.faixaVolume5);
    const faixaVolume4 = parseInt(product?.faixaVolume4 || product?.price_ranges?.faixaVolume4);
    const faixaVolume3 = parseInt(product?.faixaVolume3 || product?.price_ranges?.faixaVolume3);
    const faixaVolume2 = parseInt(product?.faixaVolume2 || product?.price_ranges?.faixaVolume2);

    const kdkg5 = parseFloat(product?.kdkg5 || product?.price_ranges?.kdkg5);
    const kdkg4 = parseFloat(product?.kdkg4 || product?.price_ranges?.kdkg4);
    const kdkg3 = parseFloat(product?.kdkg3 || product?.price_ranges?.kdkg3);
    const kdkg2 = parseFloat(product?.kdkg2 || product?.price_ranges?.kdkg2);

    if (product?.total >= faixaVolume5 && kdkg5 > 0) {
        usePrice = kdkg5;
    } else if (product?.total >= faixaVolume4 && kdkg4 > 0) {
        usePrice = kdkg4;
    } else if (product?.total >= faixaVolume3 && kdkg3 > 0) {
        usePrice = kdkg3;
    } else if (product?.total >= faixaVolume2 && kdkg2 > 0) {
        usePrice = kdkg2;
    }

    return usePrice;
}

function formatPagination(pagination) {
    let currentPage = pagination.currentPage;
    let lastPage = pagination.lastPage;
    let pages = [currentPage];

    let bottomLimit = window.innerWidth >= 490 ? 7 : 1;
    let topLimit = window.innerWidth >= 490 ? 6 : 1;

    for (let i = 1; i <= bottomLimit; i++) {
        let toAdd = currentPage - i;
        if (toAdd < 1) {
            break;
        }
        pages.push(toAdd);
    }

    for (let i = 1; i <= topLimit; i++) {
        let toAdd = currentPage + i;
        if (toAdd >= lastPage) {
            break;
        }
        pages.push(toAdd);
    }
    pages.sort((a, b) => a - b);

    pagination.pages = pages;

    return pagination
}