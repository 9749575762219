<script setup>
import { useStore } from "vuex";
const store = useStore();

const closeSidebarOnRouteChange = () => {
  const show_nav = document.getElementsByClassName("g-sidenav-show")[0]
  if (show_nav && window.innerWidth < 1200){
    show_nav.classList.remove("g-sidenav-pinned");
    show_nav.classList.add("g-sidenav-hidden");
  }
  store.state.isPinned = false;
};

defineProps({
  to: {
    type: [Object, String],
    required: true,
  },
  miniIcon: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <li class="nav-item">
    <router-link class="nav-link" :to="to" @click="closeSidebarOnRouteChange">
      <span class="sidenav-mini-icon"><i class='ni text-primary text-sm opacity-10' :class="miniIcon"></i></span>
      <span class="sidenav-normal"><i class='ni text-primary text-sm opacity-10 me-2' :class="miniIcon"></i> {{ text }} </span>
    </router-link>
  </li>
</template>
