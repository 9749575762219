<template>
  <div class="card-header bg-transparent container text-center mb-0 w-85 mx-auto w-lg-50 pb-0 d-flex flex-column align-items-center ">
    <img :src="logo" class="card-img-top col w-60 w-lg-80 " alt="Logo Conecta Brasal">
    <h5 class="text-dark text-center mt-0">{{ props.title }}</h5>
    <p class="card-text text-center ">{{ props.subtitle }}</p>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const logo = ref(require('@/assets/img/logos/brasal/logo-conecta.svg'));
const props = defineProps({
  title: String,
  subtitle: String,
});

</script>