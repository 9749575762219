import Vuex from 'vuex';
import axios from "@/axios/axios_users";
import router from "@/router";
import authStore from "./authStore"

export default new Vuex.Store({
    state: {
        companies: [],
        companiesError: false,
        loading: false,
        groupRequestLoading: false,
        error: null,
        groupRequests: [],
        groupRequestsError: false,
        createGroupError: null,
        createGroupErrorMessage: null,
        validateName: null
    },
    mutations: {
        setCompanies(state, companies) {
            state.companies = companies;
        },
        addCompany(state, company) {
            state.companies.push(company);
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setError(state, error) {
            state.error = error
        },
        setGroupRequests(state, groupRequests) {
            state.groupRequests = groupRequests;
        },
        addGroupRequest(state, groupRequest) {
            state.groupRequests.push(groupRequest);
        },
        setGroupRequestsError(state, groupRequestsError) {
            state.groupRequestsError = groupRequestsError;
        },
        setCreateGroupError(state, createGroupError) {
            state.createGroupError = createGroupError;
        },
        setGroupRequestLoading(state, groupRequestLoading) {
            state.groupRequestLoading = groupRequestLoading
        },
        setValidateName(state, validateName) {
            state.validateName = validateName
        },
        setCreateGroupErrorMessage(state, createGroupErrorMessage) {
            state.createGroupErrorMessage = createGroupErrorMessage
        }
    },
    actions: {
        async fetchCompanies({commit} ) {
            commit('setLoading', true);
            await axios.get(`/client/myCompanies`)
                .then(response => {
                    commit('setCompanies', response.data);
                })
                .catch(
                    response => {
                        commit('setError', response?.data?.message);
                    }
                )
                .finally(() => {
                    commit('setLoading', false);
                });
        },
        async fetchCompanyPage({commit}, {page}) {
            await axios.get(`/client/myCompanies${page ? `?page=${page}` : ''}`)
                .then(response => {
                    commit('setCompanies', response.data);
                })
                .catch(
                    response => {
                        commit('setError', response.data.message);
                    }
                )
                .finally(() => {
                    commit('setLoading', false);
                });
        }
        ,
        async changeCompany({commit}, clientCode) {
            commit('setLoading', true);
            await axios.post('auth/loginWithAnotherCompany', {'clientCode': clientCode})
                .then(response => {
                    if (localStorage.getItem('clientData')){
                        localStorage.removeItem('clientData')
                    }
                    if (localStorage.getItem('productsOnCart')) {
                        localStorage.removeItem('productsOnCart');
                    }
                    if (localStorage.getItem('promotions')) {
                        localStorage.removeItem('promotions');
                    }
                    localStorage.setItem('token', JSON.stringify({
                        "token": response.data.access_token,
                        "expires_in": response.data.expires_in,
                        "issued_at": Math.floor(Date.now() / 1000),
                        "token_type": response.data.token_type
                    }));
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    localStorage.setItem('isOffcanvasOpen', false);
                    authStore().commit('SET_TOKEN', {
                        token: response.data.access_token,
                        expires_in: response.data.expires_in,
                        token_type: response.data.token_type,
                        issued_at: Math.floor(Date.now() / 1000)
                    });
                    authStore().commit('SET_USER', response.data.user);

                    if (response.data.user.storeAccess) {
                        window.location.reload();
                        window.location.href = router.resolve({name: 'Lista de Produtos'}).href;
                    } else {
                        window.location.reload();
                        window.location.href = router.resolve({name: 'Campanhas'}).href;
                    }

                }).finally(() => {
                    commit('setLoading', false);
                });
        }
        ,

        async createCompanyGroup({commit}, companyGroup) {
            commit('setGroupRequestLoading', true);
            await axios.post('/group-requests', companyGroup)
                .then(() => {
                        commit('setCreateGroupError', false);
                        commit('setCreateGroupErrorMessage', null);
                    }
                )
                .catch(
                    response => {
                        commit('setCreateGroupError', true);
                        commit('setCreateGroupErrorMessage', response.response.data.message);
                    }
                )
                .finally(() => {
                    commit('setGroupRequestLoading', false);
                })
        },

        async fetchGroupRequests({commit}) {
            commit('setGroupRequestLoading', true);
            const userCodCli = JSON.parse(localStorage.getItem('user'))?.current_client.clientCode;
            await axios.get('/group-requests', {params: {
                    client_code: userCodCli
                }
            })
                .then(response => {
                    commit('setGroupRequests', response.data);
                })
                .catch(
                    response => {
                        commit('setGroupRequestsErrorMessage', response.data.message);
                        commit('setGroupRequestsError', true);

                    }
                )
                .finally(() => {
                    commit('setGroupRequestLoading', false);
                })},
        async validateName({commit}, name) {
            await axios.get('/group-requests/validate-name', {params: {
                    name: name
                }
            })
                .then(() => {
                    commit('setValidateName', true);
                })
                .catch(
                    () => {
                        commit('setValidateName', false);
                    }
                )

        }
    },
    getters: {
        companies: state => state.companies,
        groupRequests: state => state.groupRequests,
        error: state => state.error,
        groupRequestsError: state => state.groupRequestsError,
        createGroupError: state => state.createGroupError,
        createGroupErrorMessage: state => state.createGroupErrorMessage,
        isValidateName: state => state.validateName,
    }
});