<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import useAuthStore from '../../../store/authStore.js'

import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import CardHeader from "@/views/conecta-brasal-cliente/auth/components/CardHeader.vue";
import FeedBack from "@/views/conecta-brasal-cliente/auth/components/FeedBack.vue";

const authStore = useAuthStore();
const store = useStore();

const emailSent = ref(false);
const loadingSendResetEmail = ref(false);
const emailError = ref('');
const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

const email = ref('');
const image = require("@/assets/img/refrigerantes02.jpg");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
});
const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const sendResetEmail = async () => {
  emailError.value = ''
  const emailValue = email.value.trim();
  if (!isValidEmail(emailValue)) {
    emailError.value = 'Por favor, insira um e-mail válido';
    return;
  }
  loadingSendResetEmail.value = true;
  await authStore.dispatch('resetPass', {email: emailValue})
  if (authStore.state.resetEmailSent) {
    emailSent.value = true;
  }
  loadingSendResetEmail.value = false;
};
</script>

<template>


  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100" :style="{ backgroundImage: `url(${image})` }">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center mx-auto ">
          <div class="col-lg-8 col-md-8 my-2 mw-100">
            <div v-if="!emailSent" class="card ms-0 border-0 my-2 py-3 py-lg-5 d-flex flex-column ">
              <CardHeader title="Recuperar senha" subtitle="Digite seu e-mail e enviaremos uma senha temporária. É muito importante que altere a senha após o primeiro acesso." />
              <div class="border-start border-1 d-none d-lg-block mx-2"></div>

              <div class="card-body w-100 w-lg-50 mx-lg-auto">
                <div class="d-lg-none text-center text-muted mb-4">
                  <p class="text-sm font-weight-bold mb- text-secondary text-border d-inline z-index-2 bg-white px-3"></p>
                </div>
                <form  role="form" class="text-start col">
                  <div class="mb-3 row">
                    <argon-label for="email">Email</argon-label>
                    <argon-input
                        id="email"
                        v-model="email"
                        type="email"
                        placeholder="Digite seu e-mail"
                        aria-label="Email"
                        :error="emailError !== ''"
                        :class="{'input-error': emailError !== '' }"
                    />
                    <p v-if="emailError" class="error-message">{{ emailError }}</p>
                    <p v-else class="error-message">&nbsp;</p>
                  </div>

                  <div class="text-center">
                    <argon-button
                        :disabled="loadingSendResetEmail"
                        color="primary"
                        full-width
                        class="mt-1 mb-2"
                        @click.prevent="sendResetEmail"
                    >{{!loadingSendResetEmail ? 'Enviar senha temporária' : 'Carregando...' }}</argon-button>
                  </div>
                </form>
                <div class="card-footer bg-transparent text-center p-0 mt-2">
                  <p class="text-center text-muted mb-0 cursor-pointer">
                    <a class="text-center text-muted mb-0 text-sm text-nowrap"> Já possui a senha? <router-link :to="{ name: 'SignIn' }" class="segment-primary-color" > Volte ao login.</router-link></a>
                  </p>
                </div>
              </div>
            </div>
            <div v-else>
              <FeedBack icon="success" title="Email enviado" subtitle="Enviamos um e-mail com uma senha temporária. Verifique sua caixa de entrada e siga as instruções." />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
<style scoped>
.error-message {
  color: red;
  font-size: 0.8em;
  visibility: hidden; /* Adicione esta linha */
  margin-bottom: 0;
  margin-top: -15px;
}

.input-error + .error-message {
  visibility: visible; /* Adicione esta linha */
}
</style>