import axios from 'axios'
import router from "@/router";

let baseURL;
switch (process.env.AMBIENTE) {
    case 'dev':
        baseURL = process.env.APP_API_URL_DEV_PRODUCTS;
        break;
    case 'hml':
        baseURL = process.env.APP_API_URL_HML_PRODUCTS;
        break;
    case 'prod':
        baseURL = process.env.APP_API_URL_PROD_PRODUCTS;
        break;
    default:
        baseURL = process.env.APP_API_URL_LOCAL_PRODUCTS;
        break;
}

const instance = axios.create({ baseURL });

instance.interceptors.request.use(
    config => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token && token.token) {
            config.headers.Authorization = `Bearer ${token.token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            const { config, response: { status }, request: {responseURL} } = error;
            console.log(error);
            if (status === 401) {
                router.push('/auth/signin');
            } else if ((status === 400 || status === 500) && config && config.retry !== false) {
                const excludedRoutes = ['/sales', '/sales/'];
                const isExcludedRoute = excludedRoutes.some(route => responseURL.endsWith(route));

                if (!isExcludedRoute) {
                    return retryRequest(config);
                }
            }
        }
        return Promise.reject(error);
    }
);

function retryRequest(config) {
    config.retry = false;

    return instance(config);
}

export default instance;
