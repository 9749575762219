<script setup>
import { defineProps, ref, computed, watch } from "vue";

const props = defineProps({
  productName: {
    type: String,
    required: true,
  },
  productImg: {
    type: String,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
  stock: {
    type: Number,
    required: true,
  },
  addToCart: {
    type: Function,
    required: true,
  },
  isOnCart: {
    type: Boolean,
    required: true,
  },
  totalOnCart: {
    type: Number,
    required: true,
  },
  faixaVolume2: {
    type: Number,
    required: true,
  },
  faixaVolume3: {
    type: Number,
    required: true,
  },
  faixaVolume4: {
    type: Number,
    required: true,
  },
  faixaVolume5: {
    type: Number,
    required: true,
  },
  kdkg2: {
    type: Number,
    required: true,
  },
  kdkg3: {
    type: Number,
    required: true,
  },
  kdkg4: {
    type: Number,
    required: true,
  },
  kdkg5: {
    type: Number,
    required: true,
  },

});

const total = ref(props.totalOnCart);

const usePrice = computed(() => checkPriceRange(total));
const priceRanges = computed(() => listPriceRanges());
function listPriceRanges() {
  let list = [];
  if (props.kdkg5 > 0) {
    list.push(
      `${props.faixaVolume5} CX R$${props.kdkg5.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} cada`
    );
  }
  if (props.kdkg4 > 0) {
    list.push(
      `${props.faixaVolume4} CX R$${props.kdkg4.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} cada`
    );
  }
  if (props.kdkg3 > 0) {
    list.push(
      `${props.faixaVolume3} CX R$${props.kdkg3.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} cada`
    );
  }
  if (props.kdkg2 > 0) {
    list.push(
      `${props.faixaVolume2} CX R$${props.kdkg2.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} cada`
    );
  }
  return list;
}

function checkPriceRange(total) {
  let usePrice = props.price;
  if (
    total.value >= props.faixaVolume5 &&
    props.kdkg5 > 0
  ) {
    usePrice = props.kdkg5;
  } else if (
    total.value >= props.faixaVolume4 &&
    props.kdkg4 > 0
  ) {
    usePrice = props.kdkg4;
  } else if (
    total.value >= props.faixaVolume3 &&
    props.kdkg3 > 0
  ) {
    usePrice = props.kdkg3;
  } else if (
    total.value >= props.faixaVolume2 &&
    props.kdkg2 > 0
  ) {
    usePrice = props.kdkg2;
  }

  return usePrice.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

watch(
  () => props.totalOnCart,
  (newValue) => {
    total.value = newValue;
  }
);
function validateInput(event) {
  const inputValue = event.target.value;
  const newValue = inputValue ? parseInt(inputValue.replace(/\D/g, "")) : 0;
  event.target.value = newValue;
  total.value = newValue;

  props.addToCart(props.sku, total.value);


}
function increase() {
  total.value++;
  props.addToCart(props.sku, total.value);

}
function decrease() {
  total.value = total.value > 0 ? total.value - 1 : 0;
  props.addToCart(props.sku, total.value);

}
</script>
<template>
  <div class="product-card">
    <img
      v-if="productImg"
      class="product-image"
      :src="productImg"
      :alt="productName"
    />
    <div v-else class="m-auto ms-2 ml-2 text-center">
      <h5>{{ productName }}</h5>
    </div>
    <div class="product-info">
      <div class="title-container">
        <h5 class="product-title mt-1">{{ productName }}</h5>
      </div>
      <div class="price-ranges">
        <span v-for="(range, index) in priceRanges" :key="index">{{
          range
        }}</span>
      </div>
      <div
        class="product-price d-flex flex-direction-row justify-content-between"
      >
        <span class="price">R${{ usePrice }}</span>
        <span v-if="stock >= 200" class="stock-info text-success"
          >Disponível</span
        >
        <span v-else-if="stock" class="stock-info text-warning"
          >Estoque Baixo</span
        >
        <span v-else class="stock-info text-danger">Indisponível</span>
      </div>
    </div>

    <div class="product-actions">
      <div v-if="stock" class="form-group m-0 me-2 ms-2 mb-1 w-95 product-input-div">
        <div class="input-group flex-nowrap mb-1">
          <button class="btn btn-danger mb-0" type="button" @click="decrease">
            <span>-</span>
          </button>
          <input
            v-model="total"
            class="form-control changeInputInput"
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            min="0"
            max="9999"
            step="1"
            @input="validateInput"
          />
          <button class="btn btn-danger mb-0" type="button" @click="increase">
            <span class="inputSpan">+</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.disabled-link {
  pointer-events: none;
  text-decoration: none;
  cursor: not-allowed;
}
.product-card {
  height: 100%;
  position: relative;
  margin: 0.25rem;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: auto;
}

.product-image {
  width: 60%;
  margin: auto;
  margin-top: 5px;
  object-fit: contain;
  margin-bottom: 10px;
}

.product-info {
  flex: 1;
  margin-top: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-title {
  font-size: 1rem;
}

.product-price {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
}

.price {
  font-size: 1rem;
  font-weight: 700;
  color: rgb(52, 71, 103);
}

.favorite-icon {
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}

.product-btn {
  width: 95%;
}

@media screen and (max-width: 500px) {
  .btn-icon {
    display: none !important;
  }

  .product-price {
    flex-direction: column;
    font-size: 12px !important;
  }

  .product-btn {
    font-size: 10px !important;
    margin: 5px !important;
    margin-left: 2px !important;
  }

  .product-input-div {
    margin-right: 0 !important;
  }

  .product-title {
    font-size: 0.7em !important;
  }

  .product-image {
    width: 90%;
  }
}

.price-ranges {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.7em;
}

.stock-info {
  font-size: 0.9em;
}

@media screen and (max-width: 495px) {
  .price {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 440px) {
  .btn-icon {
    display: none;
  }
  .product-btn {
    font-size: 10px !important;
    margin-bottom: 5px !important;
  }

  .price-ranges {
    font-size: 0.4em;
  }
}

.text-warning2 {
  color: #ffbd2d;
}

.product-input-div .input-group {
  display: flex;
  width: 100%;
}

.ps-2 {
  padding-left: 10px;
}

.changeInput {
  margin-bottom: 0 !important;
  width: 5px !important;
}

.product-input {
  width: 50px !important;
  flex: 1 1 auto;
  min-width: 40px !important;
}

.input-group {
  display: flex;
  flex-wrap: nowrap;
}

.changeInputInput {
  flex: 1;
  text-align: center;
}

.product-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
