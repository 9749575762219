<script setup>
import { ref } from "vue";

const activeTab = ref(0);

const tabs = [
  { id: 'product-services', label: 'App', iconClass: 'feather-settings font-medium-3 me-1' },
  { id: 'my-order', label: 'Pedidos', iconClass: 'feather-package font-medium-3 me-1' },
  { id: 'delivery', label: 'Entregas', iconClass: 'feather-truck font-medium-3 me-1' },
  { id: 'cancellation-return', label: 'Cadastro', iconClass: 'feather-folder font-medium-3 me-1' }
];
</script>

<template>
  <div class="container">
    <div class="box">
      <div class="content-body">
        <!-- search header -->
        <section id="faq-search-filter">
          <div class="card faq-search">
            <div class="card-body text-center">
              <h2 class="">Dúvidas Frequentes</h2>
            </div>
          </div>
        </section>
        <!-- /search header -->

        <!-- frequently asked questions tabs pills -->
        <section id="faq-tabs">
          <!-- vertical tab pill -->
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-12">
              <div class="faq-navigation d-flex justify-content-between flex-column mb-2 mb-md-0">
                <!-- pill tabs navigation -->
                <ul class="nav nav-pills flex-column nav-left p-2 card" role="tablist">
                  <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
                    <a
                      :id="tab.id"
                      :class="{ 'nav-link': true, 'active': activeTab === index, 'bg-primary': activeTab === index, 'text-white': activeTab === index }"
                      data-bs-toggle="pill"
                      :href="'#faq-' + tab.id"
                      aria-expanded="false"
                      role="tab"
                      @click="activeTab = index"
                    >
                      <i :class="tab.iconClass"></i>
                      <span class="fw-bold">{{ tab.label }}</span>
                    </a>
                  </li>
                </ul>

                <!-- FAQ image -->
<!--                <img src="/img/faq-illustrations.svg" class="img-fluid d-none d-md-block" alt="img" />-->
              </div>
            </div>

            <div class="col-lg-9 col-md-8 col-sm-12">
              <!-- pill tabs tab content -->
              <div class="tab-content">
                <!-- App -->
                <div
                  id="faq-product-services"
                  class="tab-pane active"
                  role="tabpanel"
                  aria-labelledby="product-services"
                  aria-expanded="false"
                >
                  <!-- icon and header -->
<!--                  <div class="d-flex card">-->
<!--                    <div class="m-3">-->
<!--                      <h4 class="mb-0">App</h4>-->
<!--                      <span>Aqui pode ter o que procura...</span>-->
<!--                    </div>-->
<!--                  </div>-->

                  <!-- frequent answer and question  collapse  -->
                  <div
                    id="faq-product-qna"
                    class="accordion accordion-margin mt-2"
                  >
                    <div class="card">
                      <h5 id="productOne" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-product-one"
                          aria-expanded="false"
                          aria-controls="faq-product-one"
                        >
                          Como posso alterar minha senha?


                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>

                      <div
                        id="faq-product-one"
                        class="collapse accordion-collapse"
                        aria-labelledby="productOne"
                        data-bs-parent="#faq-product-qna"
                      >
                        <div class="accordion-body">
                          Você pode acessar o menu "Config. de Conta", na aba
                          "Alterar senha". Lá você deverá informar a senha
                          atual, uma nova senha e confirmar a nova senha.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <h5 id="productTwo" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-product-two"
                          aria-expanded="false"
                          aria-controls="faq-product-two"
                        >
                          Como posso ver as campanhas em que estou participando?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-product-two"
                        class="collapse accordion-collapse"
                        aria-labelledby="productTwo"
                        data-bs-parent="#faq-product-qna"
                      >
                        <div class="accordion-body">
                          Assim que você entrar no App, já estará disponível na
                          tela principal todas as campanhas ativas para o seu
                          perfil.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <h5 id="productThree" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-product-three"
                          aria-expanded="false"
                          aria-controls="faq-product-three"
                        >
                          Existe uma maneira de desativar as notificações?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-product-three"
                        class="collapse"
                        aria-labelledby="productThree"
                        data-bs-parent="#faq-product-qna"
                      >
                        <div class="accordion-body">
                          Sim! Você pode acessar o menu “Config. de Conta”.
                          Clique na opção “Notificações” e informe que não
                          deseja mais recebe-las.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <h5 id="productFour" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-product-four"
                          aria-expanded="false"
                          aria-controls="faq-product-four"
                        >
                          Esqueci minha senha e não tenho mais acesso ao e-mail cadastrado. O que devo fazer?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-product-four"
                        class="collapse accordion-collapse"
                        aria-labelledby="productFour"
                        data-bs-parent="#faq-product-qna"
                      >
                        <div class="accordion-body">
                          Para realizar a recuperação de senha é necessário que
                          se tenha um e-mail válido. Nesse caso, entre em
                          contato no 0800 061 5000 e solicite a atualização do
                          seu e-mail. OBS: será necessário que você responda
                          algumas perguntas ao operador para fins de segurança.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Pedidos -->
                <div
                  id="faq-my-order"
                  class="tab-pane"
                  role="tabpanel"
                  aria-labelledby="my-order"
                  aria-expanded="false"
                >
                  <!-- icon and header -->
<!--                  <div class="d-flex card">-->
<!--                    <div class="m-3">-->
<!--                      <h4 class="mb-0">Pedidos</h4>-->
<!--                      <span>Aqui pode ter o que procura...</span>-->
<!--                    </div>-->
<!--                  </div>-->

                  <!-- frequent answer and question  collapse  -->
                  <div
                    id="faq-my-order-qna"
                    class="accordion accordion-margin mt-2"
                  >
                    <div class="card">
                      <h5 id="myOrderOne" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-my-order-one"
                          aria-expanded="false"
                          aria-controls="faq-my-order-one"
                          :style="{fontSize: '8px'}"
                        >
                          O vendedor não passou hoje, ou a minha loja estava fechada no momento. Vou ficar sem pedido?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>

                      <div
                        id="faq-my-order-one"
                        class="collapse accordion-collapse"
                        aria-labelledby="myOrderOne"
                        data-bs-parent="#faq-my-order-qna"
                      >
                        <div class="accordion-body">
                          Você pode realizar seu pedido pelo canal de
                          atendimento do WhatsApp pelo número 61 98411-0333,
                          onde você poderá fazer seus pedidos até às 15:30hs. Ao
                          realizar o pedido nesse canal, enviaremos seus
                          produtos no dia correto da entrega. Caso seja o seu
                          primeiro acesso, algumas perguntas serão feitas pelo
                          nosso chatbot para confirmar o seu cadastro. Com isso
                          seus próximos acessos serão bem rápidos e práticos!
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <h5 id="myOrderTwo" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-my-order-two"
                          aria-expanded="false"
                          aria-controls="faq-my-order-two"
                        >
                          Nem todos os produtos que pedi ao vendedor foram entregues e nem constam na nota fiscal. O que devo fazer?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-my-order-two"
                        class="collapse accordion-collapse"
                        aria-labelledby="myOrderTwo"
                        data-bs-parent="#faq-my-order-qna"
                      >
                        <div class="accordion-body">
                          A Brasal trabalha incansavelmente para atender a 100%
                          da demanda dos nossos clientes. Contudo, é possível
                          que eventualmente não haja estoque suficiente para
                          suprir toda a demanda. É importante que, ao receber o
                          seu pedido, os produtos entregues estejam em
                          conformidade com a descrição da nota fiscal. Nesse
                          caso, não se preocupe! Os itens faltantes não serão
                          cobrados.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <h5 id="myOrderThree" class="accordion-header">
                        <button
                          role="button"
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-my-order-three"
                          aria-expanded="false"
                          aria-controls="faq-my-order-three"
                        >
                          Como posso aumentar o meu limite de compra?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-my-order-three"
                        class="collapse"
                        aria-labelledby="myOrderThree"
                        data-bs-parent="#faq-my-order-qna"
                      >
                        <div class="accordion-body">
                          Procure o seu vendedor e faça a solicitação. Será
                          necessário compartilhar 3 referências de outros
                          fornecedores com o limite desejado. A análise será
                          realizada pelo departamento financeiro e o vendedor
                          dará retorno se a solicitação foi aprovada ou não.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <h5 id="myOrderFour" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-my-order-four"
                          aria-expanded="false"
                          aria-controls="faq-my-order-four"
                        >
                          Como posso aumentar o meu prazo de pagamento?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-my-order-four"
                        class="collapse"
                        aria-labelledby="myOrderfour"
                        data-bs-parent="#faq-my-order-qna"
                      >
                        <div class="accordion-body">
                          Procure o seu vendedor e faça a solicitação. Será
                          necessário compartilhar 3 referências de outros
                          fornecedores com o prazo desejado. A análise será
                          realizada pelo departamento financeiro e o vendedor
                          dará retorno se a solicitação foi aprovada ou não.
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <h5 id="myOrderFive" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-my-order-five"
                          aria-expanded="false"
                          aria-controls="faq-my-order-five"
                        >
                          Não gostei de uma atitude do vendedor. Como posso reportar isso?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-my-order-five"
                        class="collapse"
                        aria-labelledby="myOrderFive"
                        data-bs-parent="#faq-my-order-qna"
                      >
                        <div class="accordion-body">
                          Nós prezamos pelo excelente atendimento aos nossos
                          clientes. Caso se sinta desconfortável com alguma
                          situação, por favor entre em contato no 0800 061 5000 e
                          faça uma reclamação. O fato será apurado e tomadas as
                          providências necessárias.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Entregas -->
                <div
                  id="faq-delivery"
                  class="tab-pane"
                  role="tabpanel"
                  aria-labelledby="delivery"
                  aria-expanded="false"
                >
                  <!-- icon and header -->
<!--                  <div class="d-flex card">-->
<!--                    <div class="m-3">-->
<!--                      <h4 class="mb-0">Entregas</h4>-->
<!--                      <span>Aqui pode ter o que procura...</span>-->
<!--                    </div>-->
<!--                  </div>-->

                  <!-- frequent answer and question  collapse  -->
                  <div
                    id="faq-delivery-qna"
                    class="accordion accordion-margin mt-2"
                  >
                    <div class="card">
                      <h5 id="deliveryOne" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-delivery-one"
                          aria-expanded="false"
                          aria-controls="faq-delivery-one"
                        >
                          O meu pedido chegou incompleto. O produto consta na
                          nota fiscal, mas não está sendo entregue fisicamente.
                          O que fazer?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>

                      <div
                        id="faq-delivery-one"
                        class="collapse accordion-collapse"
                        aria-labelledby="deliveryOne"
                        data-bs-parent="#faq-delivery-qna"
                      >
                        <div class="accordion-body">
                          Os produtos que você receber fisicamente precisam
                          estar 100% de acordo com as quantidades descritas na
                          nota fiscal. Caso haja alguma diferença, registre os
                          itens e quantidades no campo de observações do canhoto
                          da nota fiscal e comunique ao motorista, pois a Brasal
                          fará a reposição do item faltante na próxima entrega.
                          É importante que a conferência seja feita no momento
                          da entrega, pois não há procedimento de reposição após
                          a conclusão do recebimento. Contudo, você tem todo o
                          direito de não receber o pedido caso essa diferença
                          exista. Nesse caso, sua próxima entrega será realizada
                          no dia correto da rota.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <h5 id="deliveryTwo" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-delivery-two"
                          aria-expanded="false"
                          aria-controls="faq-delivery-two"
                        >
                          Gostaria de alterar o meu dia de entrega. Como posso fazer isso?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-delivery-two"
                        class="collapse accordion-collapse"
                        aria-labelledby="deliveryTwo"
                        data-bs-parent="#faq-delivery-qna"
                      >
                        <div class="accordion-body">
                          Os dias de entrega definidos para cada cliente são
                          planejados de forma que a nossa operação esteja apta a
                          atender a todas as demandas de entrega. Nesse caso, é
                          importante que você se mantenha no dia indicado para
                          não acarretar em atrasos ou não atendimento aos demais
                          clientes.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <h5 id="deliveryThree" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-delivery-three"
                          aria-expanded="false"
                          aria-controls="faq-delivery-three"
                        >
                          Meu pedido não chegou no dia combinado. O que devo fazer?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-delivery-three"
                        class="collapse"
                        aria-labelledby="deliveryThree"
                        data-bs-parent="#faq-delivery-qna"
                      >
                        <div class="accordion-body">
                          Tenha o seu CNPJ ou código de cliente em mãos e entre
                          em contato com o departamento logístico da Brasal
                          Refrigerantes para obter informações. Se o seu
                          estabelecimento está situado no DF, entre em contato
                          pelo número 61 3356-9816. Para estabelecimentos
                          situados em outras localidades, o contato se dá pelo
                          número 0800 061 5000.
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <h5 id="deliveryFour" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-delivery-four"
                          aria-expanded="false"
                          aria-controls="faq-delivery-four"
                        >
                          Não gostei de uma atitude do motorista. Como posso reportar isso?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-delivery-four"
                        class="collapse"
                        aria-labelledby="deliveryFour"
                        data-bs-parent="#faq-delivery-qna"
                      >
                        <div class="accordion-body">
                          Nós prezamos pelo excelente atendimento aos nossos
                          clientes. Caso se sinta desconfortável com alguma
                          situação, por favor entre em contato no 0800 061 5000 e
                          faça uma reclamação. O fato será apurado e tomadas as
                          providências necessárias.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Cadasro -->
                <div
                  id="faq-cancellation-return"
                  class="tab-pane"
                  role="tabpanel"
                  aria-labelledby="cancellation-return"
                  aria-expanded="false"
                >
                  <!-- icon and header -->
<!--                  <div class="d-flex card">-->
<!--                    <div class="m-3">-->
<!--                      <h4 class="mb-0">Cadastro</h4>-->
<!--                      <span>Aqui pode ter o que procura...</span>-->
<!--                    </div>-->
<!--                  </div>-->

                  <!-- frequent answer and question  collapse  -->
                  <div
                    id="faq-cancellation-qna"
                    class="accordion accordion-margin mt-2"
                  >
                    <div class="card">
                      <h5 id="cancellationOne" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-cancellation-one"
                          aria-expanded="false"
                          aria-controls="faq-cancellation-one"
                        >
                          Estou com um problema na SEFAZ. Ainda posso comprar com a Brasal?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>

                      <div
                        id="faq-cancellation-one"
                        class="collapse"
                        aria-labelledby="cancellationOne"
                        data-bs-parent="#faq-cancellation-qna"
                      >
                        <div class="accordion-body ">
                          A sua situação precisa estar regularizada junto à
                          SEFAZ para que a Brasal consiga emitir nota fiscal.
                          Toda nota fiscal é emitida eletronicamente e passa
                          pela validação da SEFAZ, que rejeita a emissão da nota
                          fiscal sempre que houver alguma irregularidade. Por
                          esse motivo, não será possível concluir a sua compra.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <h5 id="cancellationTwo" class="accordion-header">
                        <button
                          class="accordion-button collapsed small-text"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#faq-cancellation-two"
                          aria-expanded="false"
                          aria-controls="faq-cancellation-two"
                        >
                          Vou mudar o meu CNPJ ou alguma informação cadastral e preciso alterar. Como posso fazer isso?
                          <i
                              class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
                          ></i>
                          <i
                              class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
                          ></i>
                        </button>
                      </h5>
                      <div
                        id="faq-cancellation-two"
                        class="collapse"
                        aria-labelledby="cancellationTwo"
                        data-bs-parent="#faq-cancellation-qna"
                      >
                        <div class="accordion-body">
                          Procure o seu vendedor e solicite a alteração, ou, se
                          preferir, entre em contato com o nosso Serviço de
                          Atendimento ao Revendedor por meio do número 0800 061
                          5000. Algumas documentações poderão ser solicitadas
                          para efetivar a alteração.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- / frequently asked questions tabs pills -->

        <!-- contact us -->
        <section class="faq-contact card p-3 mt-2">
          <div class="row mt-5 pt-75">
            <div class="col-12 text-center">
              <h5>Você ainda tem uma pergunta?</h5>
              <p class="mb-3">
                Se você não encontrou a sua pergunta, entre em contato com o
                nosso 0800!
              </p>
            </div>
            <div class="col-sm-12">
              <div class="card text-center faq-contact-card shadow-none py-1">
                <div class="accordion-body pt-0">

                  <div class="avatar avatar-tag bg-light-danger mx-0">
                    <i class="ni ni-headphones text-primary"></i>
                  </div>
                  <h4 class="mb-0">0800 061 5000</h4>
                  <span class="text-body"
                    >Serviço de Atendimento ao Revendedor</span
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--/ contact us -->
      </div>
    </div>
  </div>
</template>
<style>
.card {
  margin-bottom: 0.8em;
}

.accordion .accordion-button.small-text {
  font-size: 0.7em !important;
  font-weight: normal;
}
.container {
  flex: 1;
}

.accordion-body{
  font-size: 0.7rem;
  padding: 12px;
  overflow: hidden;
}

.box {
  width: 100%;
}
.bg-gray {
    background-color: #CCCCCC;
}


</style>
