// authStore.js
import { createStore } from "vuex";
import axios from "@/axios/axios_users";
import showToast from "@/utils/showToast";
import router from "@/router";
import { toast } from "vue3-toastify";


export default () => createStore({
    state: {
        token: null,
        user: null,
        resetEmailSent: false,
        resendTokenSent: false,
        isOffcanvasOpen: false,
    },
    mutations: {
        SET_TOKEN(state, token) {
            localStorage.setItem("token", JSON.stringify(token));
            state.token = token;
        },
        SET_CANVAS_OPEN(state, value) {
            localStorage.setItem("isOffcanvasOpen", true);
            state.isOffcanvasOpen = value;
        },
        SET_USER(state, user) {
            localStorage.setItem("user", JSON.stringify(user));
            state.user = user;
        },
        CLEAR_AUTH(state) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            state.token = null;
            state.user = null;
        },
        SET_RESET_EMAIL_SENT(state, value) {
            state.resetEmailSent = value;
        },
        SET_RESEND_TOKEN_SENT(state, value) {
            state.resendTokenSent = value;
        },
    },
    actions: {
        async signin({commit}, {username, password}) {
            await axios.post(
                "/auth/login",
                {
                    username,
                    password,
                }
            ).then((response) => {
                const data = response.data;
                commit("SET_TOKEN", {
                    token: data.access_token,
                    expires_in: data.expires_in,
                    token_type: data.token_type,
                    issued_at: Math.floor(Date.now() / 1000),
                });

                if (!localStorage.getItem("isOffcanvasOpen") || JSON.parse(localStorage.getItem("isOffcanvasOpen")) === true) {
                    commit("SET_CANVAS_OPEN", true)
                }

                commit("SET_USER", data.user);
                showToast('success', 'Login efetuado com sucesso! Estamos redirecionando você...')


                if (data.user.storeAccess) {
                    router.push({name: 'Lista de Produtos'})
                } else {
                    router.push({name: 'Campanhas'})
                }
            }).catch((error) => {
                console.log(error)
                if (error.response) {
                    showToast('error', error.response.data.details)
                }
            });
        },
        async logout({commit}) {
            await axios.post(
                "/auth/logout"
            ).then(() => {
                localStorage.removeItem("companies");
                localStorage.removeItem("cacheTime");
                localStorage.removeItem("clientData");
                localStorage.removeItem("user")
                }
            ).catch((error) => {
                if (error.response && error.response.data.details) {
                    showToast('error', error.response.data.details)
                }
            })
            commit("CLEAR_AUTH");
            router.push({name: 'SignIn'});
        },
        async refreshToken({commit}) {
            await axios.post(
                "/auth/refreshToken"
            ).then((response) => {
                const data = response.data;
                commit("SET_TOKEN", {
                    token: data.access_token,
                    expires_in: data.expires_in,
                    token_type: data.token_type,
                    issued_at: Math.floor(Date.now() / 1000),
                });
            }).catch((error) => {
                if (error.response.status === 401) {
                    commit("CLEAR_AUTH");
                    router.push({name: 'Login Basic'})
                }
            });
        },
        async resetPass({commit}, {email}) {
            commit("CLEAR_AUTH");
            await axios.post(
                "/auth/reset-password",
                {
                    email,
                }
            ).then(() => {
                commit("SET_RESET_EMAIL_SENT", true);
            }).catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.details);
                    }
                }
            );
        },
        async resendVerificationToken({commit}, {email}) {
            await axios.post(
                "/auth/resend-token",
                {
                    email,
                }
            ).then(() => {
                commit("SET_RESEND_TOKEN_SENT", true);
                showToast('success', 'Token reenviado com sucesso! Verifique sua caixa de entrada.')
                router.push({name: 'ValidateToken'})
            }).catch((error) => {
                if (error.response) {
                    showToast('error', error.response.data.details)
                }
            });
        }
    },
});