import { createStore } from "vuex";
import axios from "@/axios/axios_campaigns";
import { ref } from "vue";

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default () => createStore({
  state: {
    clientParticipation: ref({}),
    oldCampaigns: ref([]),
    oldCampaign: ref({}),
    details: ref({}),
    
    currentCampaign: ref({}),
    currentPeriod: ref({}),

    historicPeriods: ref([]),
    historicPeriodsEvaluation: ref([]),

    periodsResults: ref({}),
    indicators: ref([]),

    detailedIndicators: ref({}),

    accumulatedPeriods: ref([]),

    bannerImages: ref([]),
    loading: false,
  },

  mutations: {
    setCampaigns(state, data) {
      state.oldCampaigns = ref(data);
    },
    setCurrentCampaign(state, data) {
      state.oldCampaign = ref(data);
    },
    setDetails(state, data) {
      state.details = ref(data);
    },
    setAccumulatedPeriods(state, data) {
      state.accumulatedPeriods = ref(data);
    },
    setClientParticipation(state, data) {
        state.clientParticipation = ref(data);
    },
    setDetailedIndicators(state, data) {
        switch (data.idprospera_indicadores) {
          case 1:
              state.detailedIndicators = ref({...state.detailedIndicators, volume: data});
            break;
          case 2:
              state.detailedIndicators = ref({...state.detailedIndicators, cover: data});
            break;
          case 3:
              state.detailedIndicators = ref({...state.detailedIndicators, priceExec: data});
            break;
          case 4:
              state.detailedIndicators = ref({...state.detailedIndicators, internalActivation: data});
            break;
          case 5:
              state.detailedIndicators = ref({...state.detailedIndicators, externalActivation: data});
            break;
          case 6:
              state.detailedIndicators = ref({...state.detailedIndicators, freezerLoad: data});
            break;
          case 7:
              state.detailedIndicators = ref({...state.detailedIndicators, exclusiveFreezer: data});
            break;
          case 8:
              state.detailedIndicators = ref({...state.detailedIndicators, sovi: data});
            break;
          default:
            break;
        }
    }
  },

  actions: {
    async getOldCampaigns(context) {

      context.state.loading = true;

      try {
        const URL = '/oldCampaign'
        context.state.loading = true;
        const response = await axios.get(URL);

        context.commit('setCampaigns', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        context.state.loading = false;
      }
    },
    async getDetails(context, params) {

      context.state.loading = true;

      const { id, type } = params;

      try {
        const URL = '/oldCampaign/buscarCampanhaID';
        context.state.loading = true;
        const response = await axios.post(URL, {id, type});
        context.commit('setDetails', response.data);
        const clientCampaignsResponse = await axios.get('/oldCampaign');
        const clienteCampaigns = clientCampaignsResponse.data;
        const isParticipating = clienteCampaigns.find(campaign => campaign.idcampanha == id)?.participando;
        context.commit('setClientParticipation', isParticipating === 'X' );
        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
    async getBanners(context, params) {
      context.state.loading = true;

      const { id, type } = params;

        try {
            const URL = '/oldCampaign/pegarBannersCampanha';
            context.state.loading = true;
            const response = await axios.post(URL, {campaignId: id, type});
            context.state.loading = false;
            context.state.bannerImages = response.data;
        } catch (error) {
            console.log(error);
            context.state.loading = false;
        }
    },
    async getCurrentPeriod(context, params) {
      context.state.loading = true;

        const {id} = params;

        try {
            const URL = '/oldCampaign/pegarProsperaPeriodo';
            context.state.loading = true;
            const response = await axios.post(URL, {campaignId: id, type: 'atual'});
            context.state.loading = false;
            context.state.currentPeriod = response.data;
        } catch (error) {
            console.log(error);
            context.state.loading = false;
        }
    },
    async getPeriod(context, params) {
      context.state.loading = true;

      const { id } = params;

      try {
        const URL = '/oldCampaign/pegarProsperaPeriodo';
        context.state.loading = true;
        const response = await axios.post(URL, {campaignId: id, type: 'historico'});
        context.state.loading = false;
        context.state.historicPeriods = response.data;
      } catch (error) {
        console.log(error);
        context.state.loading = false;
      }
    },
    async getIndicators(context, params) {
        context.state.loading = true;

        const {id} = params;

        try {
            context.state.detailedIndicators = [];
            const URL = '/oldCampaign/pegarIndicadoresProspera';
            context.state.loading = true;
            const response = await axios.post(URL, {campaignId: id});
            context.state.loading = false;
            context.state.indicators = response.data;
        } catch (error) {
            console.log(error);
            context.state.loading = false;
        }
    },
    async getIndicator(context, params) {
        context.state.loading = true;

        let { campaignId, indicatorId, dtini, dtfim, periodId } = params;

        try {
            const URL = '/oldCampaign/pegarIndicadoresProsperaID';
            context.state.loading = true;
            const response = await axios.post(URL, { campaignId, indicatorId, dtini, dtfim, periodId });
            context.state.loading = false;
            context.state.indicators = response.data;
            context.commit('setDetailedIndicators', response.data);
        } catch (error) {
            console.log(error);
            context.state.loading = false;
        }
    },
    async getCampaign(context, params) {

      context.state.loading = true;

      const { id, type } = params;

      try {
        const URL = '/oldCampaign/buscarCampanhaID';
        context.state.loading = true;
        const response = await axios.post(URL, {
            id: id,
            type: type,

        });
        
        context.commit('setCurrentCampaign', response.data);

        context.state.loading = false;

      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;

        throw error;
      }
    },
    async getAccumulatedPeriods(context, params) {

      context.state.loading = true;

      const { campaignId } = params;

      try {
        const URL = '/oldCampaign/pegarPeriodosAcumulados';
        context.state.loading = true;
        const response = await axios.post(URL, {campaignId});
        context.commit('setAccumulatedPeriods', response.data);
        context.state.loading = false;

      } catch (error) {
        console.log(error);
        context.state.loading = false;
      }
    },
    async changeTermStatus(context, params) {

      context.state.loading = true;

      const { flexibleClient, campaignId, clientTermStatusesId, type, subchannel } = params;

      try {
        const URL = '/oldCampaign/participarCampanha';
        context.state.loading = true;
        const response = await axios.put(URL, {
          clientTermStatusesId,
          type,
          subchannel
        }, {
          headers: {
            flexibleClientsCode: flexibleClient,
            flexibleCampaignsId: campaignId,
          },
        });
      

        context.state.loading = false;

        if (clientTermStatusesId == 1) {
          toast.success("Participação confirmada!");
        } else {
          toast.success("Rejeição confirmada!");
        }

        return response.data
      } catch (error) {
        console.log(error);
        if(error?.response?.data?.details) {
          toast.error(error?.response?.data?.details);
        }
        context.state.loading = false;
      }
    },
      async getPeriodsResults(context, params) {
          context.state.loading = true;

          const { campaignId } = params;

          try {
              const URL = '/oldCampaign/pegarResultadoPeriodo';
              context.state.loading = true;
              const response = await axios.post(URL, { campaignId });
              context.state.loading = false;
              context.state.periodsResults = response.data;
              context.state.historicPeriodsEvaluation = response.data;
          } catch (error) {
              console.log(error);
              context.state.loading = false;
          }
      }
  },
});