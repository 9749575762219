<script setup>

import {onBeforeMount, ref} from "vue";
import useProfileStore from "@/store/ProfileStore";
import router from "@/router";

const store = useProfileStore();
const commercialData = ref({});
const loadingData = ref(true);


onBeforeMount(async () => {
  try {
    const commercialPromise = store.dispatch("commercialPromise");

    await Promise.all([
      commercialPromise.then(() => {
        commercialData.value = store.state.commercial;
        loadingData.value = false;
      }),
    ]);
  } catch (error) {
    loadingData.value = false;
  }
});
</script>
<template>
  <div class="container my-4">
    <div class="box">
      <!-- Search Filter Section -->
      <section id="faq-search-filter" class="mb-4">
        <div class="card faq-search">
          <div class="card-body text-center">
            <!-- Main Title -->
            <h2 class="mb-2">Equipamentos</h2>
            <p class="card-text">Solicite seu novo equipamento de forma rápida e fácil.</p>
          </div>
        </div>
      </section>
      <button @click="() => router.push('/equipamentos')" class="btn btn-primary w-100">Veja seus equipamentos
      </button>
      <!-- Content Section -->
      <div class="content-wrapper container-xxl p-4 bg-white shadow-sm rounded">
        <div class="content-body">
          <!-- Section Intro -->
          <div class="mb-4">
            <h4 class="text-center">Como solicitar um novo equipamento</h4>
            <p class="text-center text-muted">Você pode solicitar seu equipamento pelos seguintes canais:</p>
          </div>

          <!-- Call Section -->
          <div class="mb-3">
            <h5>Ligue para o 0800</h5>
            <p class="text-muted">Fale diretamente com um de nossos atendentes ligando para o número <a :href="'tel:08000615000'">0800 061 5000</a>.</p>
          </div>

          <!-- Contact Seller Section -->
          <div>
            <h5>Fale com o vendedor</h5>

            <p v-if="!commercialData?.cellphone" class="text-muted">Se preferir, entre em contato diretamente com o vendedor que te atendeu.</p>
            <p v-else class="text-muted
            ">Se preferir, entre em contato diretamente com o vendedor que te atende.O número de contato do seu vendedor é <a :href="'tel:' + commercialData?.cellphone">{{ commercialData?.cellphone}}</a>.</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  max-width: 900px;
  margin: 0 auto;
}

.card {
  border: none;
  background-color: #f8f9fa;
}

.card-body h2 {
  font-size: 1.8rem;
  font-weight: bold;
}

.card-text {
  font-size: 1rem;
  color: #6c757d;
}

.content-wrapper {
  background-color: #fff;
}

.content-body h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

.content-body h5 {
  font-size: 1.2rem;
  font-weight: 500;
}

.text-muted {
  color: #6c757d;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

p {
  font-size: 1rem;
  line-height: 1.5;
}
</style>
