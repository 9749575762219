<script setup>
import { onMounted, ref, computed } from "vue";
import useOldCampaignsStore from "../../../store/OldCampaignsStore";
import { useRoute } from "vue-router";
import CoverIndicator from "./indicadoresAntigos/CoverIndicator.vue";
import VolumeIndicator from "./indicadoresAntigos/VolumeIndicator.vue";
import PriceExecutionIndicator from "./indicadoresAntigos/PriceExecutionIndicator.vue";
import InternalActivationIndicator from "./indicadoresAntigos/InternalActivationIndicator.vue";
import ExternalActivationIndicator from "./indicadoresAntigos/ExternalActivationIndicator.vue";
import FreezerLoadIndicator from "./indicadoresAntigos/FreezerLoadIndicator.vue";
import ExclusiveFreezerIndicator from "./indicadoresAntigos/ExclusiveFreezerIndicator.vue";
import SoviIndicator from "./indicadoresAntigos/SoviIndicator.vue";
import Chart from "./indicadoresAntigos/Chart.vue";
import Loading from "@/components/Loading.vue";

const route = useRoute();
const store = useOldCampaignsStore();

const campaignData = ref({});
const historicPeriodsEvaluation = ref([]);
const campaignLoading = ref(true);
const periodsLoading = ref(true);
const historicPeriodsLoading = ref(true);
const accumulatedPeriodsLoading = ref(true);

const campaignName = computed(() => campaignData.value?.nome);
const currentPeriod = ref({});

const accumulatedPeriods = ref([]);
const periodsResults = ref([]);
const resultsCurrentPeriod = ref({});

const indicators = ref([]);

const daysLeft = computed(() => {
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(0, 0, 0, 0);

  var finalDate = new Date(currentPeriod?.value?.final_date);

  if (finalDate > currentDate) {
    var diferencaMs = finalDate - currentDate;
    var diferencaDias = Math.ceil(diferencaMs / (1000 * 60 * 60 * 24));
    return diferencaDias;
  } else if (finalDate.toDateString() === currentDate.toDateString()) {
    return "menos de um dia!";
  } else {
    return -1;
  }
});

const generalLoading = computed(() => campaignLoading.value || periodsLoading.value || historicPeriodsLoading.value || accumulatedPeriodsLoading.value);
function formatDate(dataString) {
  if (dataString) {
    var partes = dataString.split("-");
    var novaData = partes[2] + "/" + partes[1] + "/" + partes[0];
    return novaData;
  } else {
    return "Data Inválida";
  }
}

onMounted(async () => {
  try {
    const campaignsPromise = store.dispatch("getCampaign", {
      id: route?.params?.id,
      type: route.params.type,
    });

    const currentPeriodPromise = store.dispatch("getCurrentPeriod", {
      id: route?.params?.id,
      type: route.params.type,
    });

    const indicatorsPromise = store.dispatch("getIndicators", {
      id: route?.params?.id,
      type: route.params.type,
    });

    const accumulatedPeriodsPromise = store.dispatch("getAccumulatedPeriods", {
      campaignId: route.params.id,
    });

    const periodsResultsPromise = store.dispatch("getPeriodsResults", {
      campaignId: route.params.id,
    });

    await Promise.all([
      campaignsPromise.then(() => {
        campaignData.value = store.state.oldCampaign;
        campaignLoading.value = false;
      }),
      currentPeriodPromise.then(() => {
        currentPeriod.value = store.state.currentPeriod;
        periodsLoading.value = false;
      }),
      periodsResultsPromise.then(() => {
        periodsResults.value = store.state.periodsResults;
        resultsCurrentPeriod.value = periodsResults.value.find(
          (period) =>
            period.periodo.idprospera_periodos ===
            currentPeriod.value.idprospera_periodos
        );

        historicPeriodsEvaluation.value = store.state.historicPeriodsEvaluation;
        historicPeriodsLoading.value = false;
      }),

      indicatorsPromise.then(() => {
        indicators.value = store.state.indicators;
      }),

      accumulatedPeriodsPromise.then(() => {
        accumulatedPeriods.value = store.state.accumulatedPeriods;
        accumulatedPeriodsLoading.value = false;
      }),
    ]);
  } catch (error) {
    console.error("Erro:", error);
    campaignLoading.value = false;
    periodsLoading.value = false;
    historicPeriodsLoading.value = false;
    accumulatedPeriodsLoading.value = false;
  }
});
function getMonthName(dateString) {
  const [year, month, day] = dateString.split('-');
  const date = new Date(year, month - 1, day); // O mês precisa ser subtraído em 1 para ajustar o índice de mês em JavaScript
  const options = { month: 'long' };
  const monthName = date.toLocaleDateString('pt-BR', options);
  return monthName.charAt(0).toUpperCase() + monthName.slice(1);
}


</script>
<template>
  <div class="container">
    <div class="content">
      <div v-if="!campaignLoading" class="card">
        <h1
          v-show="!campaignLoading && !campaignData"
          class="orders-no-products"
        >
          Campanha não encontrada...
        </h1>
        <div v-show="!campaignLoading && campaignData" class="card-body">
          <div
            style="
              width: 100%;
              text-align: center;
              font-weight: 500;
              font-size: 18px;
            "
          >
            <h3>{{ campaignName }}</h3>
          </div>
          <br />

          <div v-if="!periodsLoading && currentPeriod" class="mission-time">
            <h5
              v-if="daysLeft > 0"
              id="tempo_expira"
              class="card-text mission font-small-3"
            >
              Este período termina em
              <b style="color: red">{{ daysLeft }}</b> dias!
            </h5>
            <h5
              v-else-if="daysLeft == 0"
              id="tempo_expira"
              class="card-text mission font-small-3"
            >
              Este período termina em <b style="color: red">menos de um dia</b>!
            </h5>
            <h5
              v-else-if="daysLeft < 0"
              id="tempo_expira"
              class="card-text mission font-small-3"
            >
              <b style="color: red">Período finalizado!</b>
            </h5>
          </div>
          <div v-else-if="periodsLoading">
            <Loading class="limited-loading" short />
          </div>
          <div v-else>
            <h5 class="mb-5">Nenhum período ativo no momento!</h5>
          </div>

          <div id="logo_prosp" class="my-3 me-0 me-xl-2">
            <img
              v-if="campaignData?.imagem"
              src="/img/campanhas/img_campanha_prospera.png"
              class="congratulation-medal"
              alt="Logo Conecta Brasal"
              style="border-radius: 10px"
            />
            <img
              v-else
              src="/img/placeholder-image.png"
              class="congratulation-medal"
              alt="Logo Conecta Brasal"
              style="border-radius: 10px"
            />
          </div>

          <div class="details-btn">
            <router-link
              style="max-width: 300px"
              :to="`/campanhas-prospera/detalhes/${route?.params?.id}/${route.params.type}`"
              class="btn btn-primary btn-block"
            >
              Consultar detalhes da campanha
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Indicadores -->

  <div
    v-if="
      !campaignLoading &&
      currentPeriod &&
      currentPeriod?.dtini &&
      currentPeriod?.dtfim
    "
    class="container"
  >
    <div class="content">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 text-center">
              <div class="row">
                <div class="col-12">
                  <h4>Período Vigente</h4>
                </div>
                <div class="col-12">
                  {{
                    `${formatDate(currentPeriod?.dtini)} - ${formatDate(currentPeriod?.dtfim)}`
                  }}
                </div>
              </div>
            </div>
            <div
              v-if="resultsCurrentPeriod.resultado >= 100"
              id="trophy-icon"
              style="text-align: right"
            >
              <img id="img_trofeu" width="50px" src="/img/trofeu.png" />
            </div>
          </div>

          <div>
            <div
              id="grafico_campanha"
              style="cursor: pointer"
              :class="`progress progress-bar-${resultsCurrentPeriod?.resultado >= 100 ? 'success' : 'danger'} mt-3`"
              onclick=""
            >
              <div
                class="progress-bar"
                role="progressbar"
                :aria-valuenow="resultsCurrentPeriod?.resultado"
                :aria-valuemin="resultsCurrentPeriod?.resultado"
                aria-valuemax="100"
                :style="`width: ${resultsCurrentPeriod?.resultado === 0 ? 3 : resultsCurrentPeriod?.resultado}%`"
              >
                {{
                  `${resultsCurrentPeriod?.resultado ? resultsCurrentPeriod?.resultado?.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : 0}%`
                }}
              </div>
            </div>
          </div>

          <div v-if="!indicators.length && !campaignLoading" class="col-12 text-danger mt-4 mb-3">
            <b>Nenhum indicador ativo no momento</b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="
      !campaignLoading &&
      !currentPeriod?.value &&
      !currentPeriod?.dtini &&
      !currentPeriod?.dtfim
    "
    class="container"
  >
    <div class="content">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 text-center">
              <div class="row">
                <div class="col-12">
                  <h4 class="text-warning">Nenhum período ativo no momento!</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="
      !campaignLoading &&
      indicators?.length &&
      currentPeriod?.dtini &&
      currentPeriod?.dtfim
    "
    class="container"
  >
    <div class="content">
      <div class="card">
        <div class="card-header text-center">
          <h4>Indicadores</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="indicators-container">
              <div v-for="(indicator, index) in indicators" :key="index">
                <VolumeIndicator
                  v-if="indicator.idprospera_indicadores === 1"
                  :indicator="indicator"
                  :current-period="currentPeriod"
                  :recieved-data="false"
                />
                <CoverIndicator
                  v-if="indicator.idprospera_indicadores === 2"
                  :indicator="indicator"
                  :current-period="currentPeriod"
                  :recieved-data="false"
                />
                <PriceExecutionIndicator
                  v-if="indicator.idprospera_indicadores === 3"
                  :indicator="indicator"
                  :current-period="currentPeriod"
                  :recieved-data="false"
                />
                <InternalActivationIndicator
                  v-if="indicator.idprospera_indicadores === 4"
                  :indicator="indicator"
                  :current-period="currentPeriod"
                  :recieved-data="false"
                />
                <ExternalActivationIndicator
                  v-if="indicator.idprospera_indicadores === 5"
                  :indicator="indicator"
                  :current-period="currentPeriod"
                  :recieved-data="false"
                />
                <FreezerLoadIndicator
                  v-if="indicator.idprospera_indicadores === 6"
                  :indicator="indicator"
                  :current-period="currentPeriod"
                  :recieved-data="false"
                />
                <ExclusiveFreezerIndicator
                  v-if="indicator.idprospera_indicadores === 7"
                  :indicator="indicator"
                  :current-period="currentPeriod"
                  :recieved-data="false"
                />
                <SoviIndicator
                  v-if="indicator.idprospera_indicadores === 8"
                  :indicator="indicator"
                  :current-period="currentPeriod"
                  :recieved-data="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!accumulatedPeriodsLoading" class="container">
    <div class="card w-100 m-0">
      <h4>
        <a
          class="accordion-button collapsed"
          data-bs-toggle="collapse"
          href="#accumulatedCollapse"
          role="button"
          aria-expanded="false"
          aria-controls="accumulatedCollapse"
        >
          Períodos acumulados
          <i
            class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
          ></i>
          <i
            class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
          ></i>
        </a>
      </h4>

      <div id="accumulatedCollapse" class="collapse">
        <div class="row">
          <div
            v-for="period in Object.values(accumulatedPeriods)"
            :key="period?.periodo_info?.idprospera_periodos_acumulado"
            class="col-lg-6 col-md-6 col-12"
          >
            <div v-if="period" class="card P-2">
              <div class="card-header d-flex justify-content-between pb-0">
                <h4 class="card-title text-center">
                  {{ period?.periodo_info?.descricao }}
                </h4>
                <h4 v-if="period?.periodo_info" class="card-title text-center">
                  {{
                    `${formatDate(period?.periodo_info?.dtini)} a ${formatDate(period?.periodo_info?.dtfim)}`
                  }}
                </h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-sm-2 col-12 d-flex flex-column flex-wrap text-center"
                  >
                    <h1 class="font-large-2 fw-bolder mt-2 mb-0">
                      {{ period?.periodo_info?.resultadoTotal?.toFixed() }}
                    </h1>
                    <p class="card-text mission">Pontos</p>
                  </div>
                  <div class="col-sm-10 col-12 d-flex justify-content-center">
                    <div id="support-trackers-chart">
                      <Chart
                        :score="period?.periodo_info?.resultadoTotal"
                        :max="period?.periodo_info?.pontuacaoMaxima"
                      />
                    </div>
                    <div class="resize-triggers">
                      <div class="expand-trigger">
                        <div></div>
                      </div>
                      <div class="contract-trigger"></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <div
                    v-for="subPeriod in period.resultados"
                    :key="subPeriod.id"
                    class="text-center"
                  >
                    <p
                      v-if="subPeriod.dtini && subPeriod.dtfim"
                      class="card-text mission mb-50"
                    >
                      {{
                        `${getMonthName(subPeriod?.dtini)}`
                      }}
                    </p>
                    <span class="font-large-1 fw-bold">
                      {{ subPeriod?.porcentagem?.toFixed() }} pontos
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!historicPeriodsLoading" class="container mt-1">
    <div class="card w-100 m-0">
      <h4>
        <a
          class="accordion-button collapsed"
          data-bs-toggle="collapse"
          href="#historicCollapse"
          role="button"
          aria-expanded="false"
          aria-controls="historicCollapse"
        >
          Histórico de Períodos
          <i
            class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"
          ></i>
          <i
            class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"
          ></i>
        </a>
      </h4>

      <div id="historicCollapse" class="collapse">
        <div class="card-body">
          <div v-if="!historicPeriodsEvaluation?.length">
            <p>Sem resultados históricos</p>
          </div>
          <div
            v-for="period in historicPeriodsEvaluation"
            v-else
            :key="period?.periodo?.idprospera_periodos"
            class="accordion-item"
          >
            <div
              class="card-header"
              :idprospera_periodos="`flush-heading${period?.periodo?.idprospera_periodos}`"
            >
              <button
                class="accordion-button collapsed"
                data-bs-toggle="collapse"
                :data-bs-target="`#histPeriod${period?.periodo?.idprospera_periodos}`"
                :aria-controls="`histPeriod${period?.periodo?.idprospera_periodos}`"
                aria-expanded="false"
              >
                <div class="row w-100">
                  <div class="w-100 text-center">
                    <div id="tit_periodo_atual" class="col-12">
                      Vigência: {{ formatDate(period?.periodo?.dtini) }} a
                      {{ formatDate(period?.periodo?.dtfim) }}
                    </div>
                  </div>
                  <div
                    v-if="period?.resultado >= 100"
                    class="col-2"
                    style="text-align: right"
                  >
                    <img
                      :id="`img_trofeu_hist${period?.periodo?.idprospera_periodos}`"
                      width="50px"
                      src="/img/trofeu.png"
                    />
                  </div>
                </div>
              </button>

              <div>
                <div
                  id="grafico_campanha"
                  style="cursor: pointer"
                  :class="`progress progress-bar-${period?.resultado >= 100 ? 'success' : 'danger'} mt-3`"
                  onclick=""
                >
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :aria-valuenow="period?.resultado"
                    :aria-valuemin="period?.resultado"
                    aria-valuemax="100"
                    :style="`width: ${period?.resultado}%`"
                  >
                    {{
                      `${period?.resultado ? period?.resultado?.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : 0}%`
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div
              :id="`histPeriod${period?.periodo?.idprospera_periodos}`"
              class="collapse"
            >
              <div class="accordion-body">
                <div class="row">
                  <div class="indicators-container2">
                    <div
                      v-for="(indicator, index) in period?.resultados"
                      :key="index"
                    >
                      <VolumeIndicator
                        v-if="indicator.idprospera_indicadores === 1"
                        :indicator="indicator"
                        :current-period="currentPeriod"
                        :recieved-data="true"
                      />
                      <CoverIndicator
                        v-if="indicator.idprospera_indicadores === 2"
                        :indicator="indicator"
                        :current-period="currentPeriod"
                        :recieved-data="true"
                      />
                      <PriceExecutionIndicator
                        v-if="indicator.idprospera_indicadores === 3"
                        :indicator="indicator"
                        :current-period="currentPeriod"
                        :recieved-data="true"
                      />
                      <InternalActivationIndicator
                        v-if="indicator.idprospera_indicadores === 4"
                        :indicator="indicator"
                        :current-period="currentPeriod"
                        :recieved-data="true"
                      />
                      <ExternalActivationIndicator
                        v-if="indicator.idprospera_indicadores === 5"
                        :indicator="indicator"
                        :current-period="currentPeriod"
                        :recieved-data="true"
                      />
                      <FreezerLoadIndicator
                        v-if="indicator.idprospera_indicadores === 6"
                        :indicator="indicator"
                        :current-period="currentPeriod"
                        :recieved-data="true"
                      />
                      <ExclusiveFreezerIndicator
                        v-if="indicator.idprospera_indicadores === 7"
                        :indicator="indicator"
                        :current-period="currentPeriod"
                        :recieved-data="true"
                      />
                      <SoviIndicator
                        v-if="indicator.idprospera_indicadores === 8"
                        :indicator="indicator"
                        :current-period="currentPeriod"
                        :recieved-data="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <Loading v-if="generalLoading" short />
  </div>

  <!--  <div v-if="!campaignLoading && accumulatedPeriods?.length" class="container">-->
  <!--    <div class="card">-->
  <!--      <div class="row">-->
  <!--        <div-->
  <!--          v-for="period in accumulatedPeriods"-->
  <!--          :key="period.id"-->
  <!--          class="col-lg-6 col-md-6 col-12"-->
  <!--        >-->
  <!--          <div class="card">-->
  <!--            <div class="card-header d-flex justify-content-between pb-0">-->
  <!--              <h4 class="card-title text-center">-->
  <!--                {{ period?.accumulated_period_description }}-->
  <!--              </h4>-->
  <!--              <h4-->
  <!--                v-if="period?.flexible_periods"-->
  <!--                class="card-title text-center"-->
  <!--              >-->
  <!--                {{-->
  <!--                  `${formatDate(period?.flexible_periods[0]?.initial_date)} a ${formatDate(period?.flexible_periods[period?.flexible_periods?.length - 1]?.final_date)}`-->
  <!--                }}-->
  <!--              </h4>-->
  <!--            </div>-->
  <!--            <div class="card-body">-->
  <!--              <div class="row">-->
  <!--                <div-->
  <!--                  class="col-sm-2 col-12 d-flex flex-column flex-wrap text-center"-->
  <!--                >-->
  <!--                  <h1 class="font-large-2 fw-bolder mt-2 mb-0">-->
  <!--                    {{ period.total_client_periods_score }}-->
  <!--                  </h1>-->
  <!--                  <p class="card-text mission">Pontos</p>-->
  <!--                </div>-->
  <!--                <div class="col-sm-10 col-12 d-flex justify-content-center">-->
  <!--                  <div id="support-trackers-chart">-->
  <!--                    <div-->
  <!--                      id="chart_radios2 echo $pers['accumulated_period_id'] "-->
  <!--                    ></div>-->
  <!--                    <Chart :score="period.total_client_accumulated_score" />-->
  <!--                  </div>-->
  <!--                  <div class="resize-triggers">-->
  <!--                    <div class="expand-trigger">-->
  <!--                      <div></div>-->
  <!--                    </div>-->
  <!--                    <div class="contract-trigger"></div>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="d-flex justify-content-between mt-1">-->
  <!--                <div-->
  <!--                  v-for="subPeriod in period.flexible_periods"-->
  <!--                  :key="subPeriod.id"-->
  <!--                  class="text-center"-->
  <!--                >-->
  <!--                  <p-->
  <!--                    v-if="subPeriod.initial_date && subPeriod.final_date"-->
  <!--                    class="card-text mission mb-50"-->
  <!--                  >-->
  <!--                    {{-->
  <!--                      `${formatDate(subPeriod?.initial_date)} a ${formatDate(subPeriod?.final_date)}`-->
  <!--                    }}-->
  <!--                  </p>-->
  <!--                  <span class="font-large-1 fw-bold">-->
  <!--                    {{ subPeriod.client_score }} pontos-->
  <!--                  </span>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>
<style scoped>
.limited-loading {
  max-height: 16vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style>
#logo_prosp {
  margin-right: 20px;
  text-align: right;
}

#logo_prosp img {
  background-color: lightgrey;
  width: 170px;
}

@media screen and (max-width: 800px) {
  #logo_prosp {
    margin-top: -8px;
    width: 100%;
    text-align: center;
  }

  #logo_prosp img {
    background-color: lightgrey;
    width: 250px;
  }

  .mission-time {
    text-align: center;
  }

  .details-btn {
    text-align: center;
  }
}

#trophy-icon {
  position: absolute;
  top: auto;
  width: 50px;
  right: 15px;
}

#grafico_campanha {
  height: 15px;
}

.indicators-containe {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
  align-items: stretch;
}

#grafico_campanha {
  height: 15px;
}

.indicators-container2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
  align-items: stretch;
}

@media screen and (max-width: 1445px) {
  .indicators-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 991px) {
  .indicators-container,
  .indicators-container2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
