<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  banner_data: {
    type: Object,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  initialDate: {
    type: String,
    required: true,
  },
  finalDate: {
    type: String,
    required: true,
  },
  termStatus: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
});
function formatarCaminho(caminho) {
  const partes = caminho.split('/');
  const nomeArquivo = partes[partes.length - 1];
  return `/img/campanhas/${nomeArquivo}`;
}

const bannerImg = computed(() =>
  props.banner_data
    ? formatarCaminho(props.banner_data)
    : "/img/placeholder-image.png"
);

function formatDate(dataString) {
  if (dataString) {
    var partes = dataString.split("-");
    var novaData = partes[2] + "/" + partes[1] + "/" + partes[0];
    return novaData;
  } else {
    return 'Data Inválida'
  }
}
</script>
<template>
  <div class="card mx-auto">
    <div
      v-if="termStatus == 'X'"
      class="text-center py-2 bg-success text-white"
    >
      VOCÊ ESTÁ PARTICIPANDO
    </div>
    <div v-else class="text-center py-2 bg-warning text-white">
      VEJA COMO PARTICIPAR
    </div>
    <img
      v-if="bannerImg"
      class="campaign-image mx-auto"
      :src="bannerImg"
      :alt="id"
    />
    <div v-else class="m-auto ms-2 ml-2 text-center">
      <h5>{{ id }}</h5>
    </div>
    <div class="info">
      <h4 class="campaign-header mt-1">{{ name }}</h4>
      <h5 class="campaign-header2 mb-3">{{ subtitle }}</h5>
      <div v-if="termStatus == 'X'" class="campaing-period">
        <span>Vigência:</span
        ><span>{{
          `${formatDate(initialDate)} - ${formatDate(finalDate)}`
        }}</span>
      </div>
      <div v-else class="campaing-period">
        <span>Inscrição:</span
        ><span>{{
          `${formatDate(initialDate)} - ${formatDate(finalDate)}`
        }}</span>
      </div>

      <div class="campaign-actions d-flex align-items-center">
        <router-link
          v-if="termStatus == 'X'"
          :to="`/campanhas-prospera/${id}/${type}`"
          class="mb-0 btn btn-outline-primary export w-100 m-2 mb-2"
        >
          Ver Progresso
        </router-link>
        <router-link
          v-else
          :to="`/campanhas-prospera/detalhes/${id}/${type}`"
          class="mb-0 btn btn-outline-primary export w-100 m-2 mb-2"
        >
          Saiba Mais
        </router-link>
      </div>
    </div>
  </div>
</template>
<style>
.disabled-link {
  pointer-events: none;
  text-decoration: none;
  cursor: not-allowed;
}

.card {
  position: relative;
  margin: 0.25rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.campaign-image {
  width: 100%;
  object-fit: contain;
}

.info {
  margin-top: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.campaign-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  word-break: break-word;
}

.campaign-header2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.85rem;
  word-break: break-word;
}

.card-header {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
}

.title {
  font-size: 1rem;
  font-weight: 700;
}

.favorite-icon {
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}

.campaing-period {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 750px) {
  .campaing-period {
    flex-direction: column;
  }
}
</style>
